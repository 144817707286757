import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCompanies, getEnabledCompany } from "./CompanyActions";
import { IMaskInput } from "react-imask";
import Company from "./Company"
import { setMask, cnpjMask, cnpjValidation, onlyNumber} from "../../utils/mask"
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';
import {  formatDate } from "../../utils/Data";
import { header } from "../../utils/Datagrid";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import Loader from "components/utils/Loader"

import {
  Card,
  Form,
  Row,
  Col,
} from "react-bootstrap";

class CompanyList extends Component {
  constructor(props) {
    super(props);
    this.dt = React.createRef();
    this.state = {
      co: { search: '0' },
      filters: {
        corporate_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        fantasy_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        cnpj: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        enabled_virtual: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      },
      loading: false
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    this.props.getCompanies().then(() => {
    })
    .catch((error) => {})
    .finally(() => {
      this.setState({ loading: false })
    });
  }

  cols = [
    { field: 'corporate_name', header: 'Razão Social' },
    { field: 'fantasy_name', header: 'Nome Fantasia' },
    { field: 'cnpj', header: 'CNPJ' },
    { field: 'enabled_virtual', header: 'Ativo' }
  ];
  onChange = e => {
    var co = { ...this.state.co }
    this.setState({ loading: true })
    co[e.target.name] = e.target.value
    this.props.getEnabledCompany(this.state.co.search).then(() => {
      var ur = {...this.state}
      ur.co = co
      ur.loading = false
      this.setState(ur)
    })
    this.setState({co });
  };

  exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));
  
  dateBodyTemplate = (rowData) => {
    return formatDate(rowData.created_at);
  };

  alocBodyTemplate = (rowData) => {
    return <Company key={rowData.id} company={rowData} />
  }

  render() {
    const { companies } = this.props.companiesReduc;
    
    let items2 = companies.map(company => {            
        company.cnpj = cnpjMask(company.cnpj)
        company.enabled_virtual = company.enabled ? "Sim" : "Não"
      return company
    });
    
    return (
      <>
      <Loader loading={this.state.loading} />

      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Cadastro de Empresas Gestoras</Card.Title>
              <p className="card-category">
                Gestoras
              </p>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <div className="card">
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable ref={this.dt} value={items2} header={(<div>
                  <Row className="mb-3"></Row>
                  <Row>
                    <Col md="10">
                      <Row >
                        <Form.Group as={Col} md="5" controlId="search">
                          <Form.Label className="font-weight-bold" style={{ color: 'black', fontSize: '16px' }}>Situação</Form.Label>
                          <Form.Control
                            as="select" required name="search" value={this.state.co.search}
                            onChange={this.onChange} maxLength={100} >
                            <option value='0'>Ativo</option>
                            <option value='1'>Inativo</option>
                          </Form.Control >
                          <Form.Control.Feedback>Ok</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Campo obrigatório.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    </Col>
                    <Col>
                      {header(companies, "Gestoras", this.dt, this.exportColumns)}
                    </Col>
                  </Row>
                </div>
                )} 
                  tableStyle={{ minWidth: '50rem' }}
                  paginator rows={5}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      rowsPerPageOptions={[5, 10, 25, 50]}
                  filterDisplay="row"
                  filters={this.state.filters}
                  emptyMessage="Não há Empresas Gestoras cadastradas"
                  
                  >
                  {this.cols.map((col, index) => (
                      <Column key={index} filter sortable field={col.field} header={col.header} />
                  ))}
                  <Column body={this.alocBodyTemplate}></Column>
                </DataTable>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </>
    );
  }
}

CompanyList.propTypes = {
  getCompanies: PropTypes.func.isRequired,
  companiesReduc: PropTypes.object.isRequired,
  getEnabledCompany: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  companiesReduc: state.companiesReduc
});

export default connect(mapStateToProps, {
  getCompanies, getEnabledCompany
})(withRouter(CompanyList));