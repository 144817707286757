import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_MOVEMENTS, GET_MOVEMENT, ADD_MOVEMENT, DELETE_MOVEMENT, UPDATE_MOVEMENT, MODAL_ADD_MOVEMENT} from "./MovementTypes";
import { toast } from "react-toastify";

export const getMovements = (options={supplier:null} ) => dispatch => {

  var url = "/api/v1/movements/"
  var complement = []
  if (options.supplier){
    complement.push(`supplier=${options.supplier}`)
  }

  if (options.ncm_general){
    complement.push(`ncm_general=${options.ncm_general}`)
  }

  if (options.is_last){
    complement.push(`is_last=${options.is_last}`)
  }

  if (options.with_stock){
    complement.push(`with_stock=${options.with_stock}`)
  }

  if (options.agg){
    complement.push(`agg=1`)
  }

  if (options.start_at){
    complement.push(`start_at=${options.start_at}`)
  }

  if (options.end_at){
    complement.push(`end_at=${options.end_at}`)
  }

  if (options.nf){
    complement.push(`nf=${options.nf}`)
  }

  if (options.order_by){
    complement.push(`order_by=${options.order_by}`)
  }

  if (complement.length > 0){
    url += "?" + complement.join("&")
  }

  return axios
    .get(url)
    .then(response => {      
      dispatch({
        type: GET_MOVEMENTS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const addMovement = movement => dispatch => {
  return axios
    .post("/api/v1/movements/", movement)
    .then(response => {
      dispatch({
        type: ADD_MOVEMENT,
        payload: response.data
      });
      toastOnSave("Inclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const editMovements = (movements, nf) => dispatch => {
  let obj = { type: 'edit_nf_movements', movements: movements, id_nf: nf.id, issued_at: nf.issued_at }
  return axios
    .post("/api/v1/movements/", obj)
    .then(response => {
      dispatch({
        type: ADD_MOVEMENT,
        payload: response.data
      });
      toastOnSave("Retificaçao realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
}


export const deleteMovement = id => dispatch => {
  axios
    .delete(`/api/v1/movements/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_MOVEMENT,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getMovement = id => dispatch => {
  return axios
    .get(`/api/v1/movements/${id}/`)
    .then(response => {
      dispatch({
        type: GET_MOVEMENT,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const updateMovement = (id, company) => dispatch => {
  return axios
    .patch(`/api/v1/movements/${id}/`, company)
    .then(response => {
      dispatch({
        type: UPDATE_MOVEMENT,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const modalAddMovement = (movement) => dispatch => {
  dispatch({
    type: MODAL_ADD_MOVEMENT,
    payload: movement
  })
}