import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { deletePerson, updatePerson } from "./PersonsActions";
import { Button } from "react-bootstrap";
import { PencilFill, TrashFill, Check2Circle} from 'react-bootstrap-icons';

class Person extends Component {

  onDeleteClick = () => {
    const { person } = this.props;
    this.props.deletePerson(person.id);
  };

  render() {
    const { person } = this.props;
    return (      
      <div className="btn-group">        
        <Link to={`/manage/persons/edit/${person.id}`} role="button" className="btn btn-primary">          
          <PencilFill color="royalblue" />
        </Link>
        <Button variant="danger" className="btn btn-primary" onClick={this.onDeleteClick}>
          <TrashFill color="red" />
        </Button>
      </div>      
    );
  }
}

Person.propTypes = {
    person: PropTypes.object.isRequired
};
const mapStateToProps = state => ({});

export default connect(mapStateToProps, { deletePerson, updatePerson })(
  withRouter(Person)
);