import { Modal, Button } from 'react-bootstrap';
import React from 'react';
import { PencilFill, TrashFill, Check2Circle} from 'react-bootstrap-icons';

class ConfirmationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show: false };

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    render() {
        return (
            <>
                <Button variant="danger" className="btn btn-primary" onClick={this.handleShow}>
                    <TrashFill color="red" />
                </Button>
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmação</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><h6>{this.props.title}</h6></Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger active" onClick={this.handleClose}>
                            Cancelar
                        </Button>
                        <Button onClick={() => { this.props.function(); this.handleClose(); }}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default ConfirmationModal;