import React from 'react';
import { IMaskInput } from "react-imask";
export const cpfMask = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}
export const densityMask = value => {
  if (!value)
    return value
  value = value.toString()
  // Remover caracteres não numéricos
  const cleanValue = value.replace(/[^\d]/g, '');
  // Adicionar ponto como separador decimal
  const formattedValue = cleanValue
  .replace(/(\d*)(\d{2})/, '$1,$2');
  return formattedValue;
}
export const quantidadeMask = value => {
  
  if (!value)
    return value
  value = value.toString()
  value = value.replace(/[^\d]/g, '');
  if (!value)
    return value
  value = value
  .replace(/(\d*)(\d{3})/, '$1,$2');
  return value
}

export const cnpjMask = value => {

  if (!value)
    return value
  value = value.toString()

  return value
      .replace(/\D+/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')

}

export const telMask = value => {
    if (!value)
      return value
    value = value.toString()
    return value
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    // .replace(/\D/g, "")
    // .replace(/(\d{2})(\d{5})(\d{2})/, "$1 $2-$3")
}
  export const setMask = (value) => {
    if (!value)
      return value
    value = value.toString()
    if (value){
      if (value.length == 14) {
        var maskCnpj = cnpjMask(value)
        return maskCnpj
      } if (value.length == 11) {
        var maskCpf = cpfMask(value)
        return maskCpf
      }
    }
    return value
  };

//Only read numbers
  export const onlyNumber = value => {
    if (!value)
      return value
    value = value.toString()
    var numsStr = value?.replace(/[^0-9]/g, '');
    return numsStr;
  }

 //Validation cnpj
 export const cnpjValidation = cnpj => {
    if (!cnpj)
      return cnpj
    cnpj = cnpj.toString()
    var numcnpj = onlyNumber(cnpj)
    var strcnpj = String(numcnpj)
    if (cnpj == '') {
      return false
    }
    if (strcnpj.length != 14) {
      return false
    }

    if (strcnpj == "00000000000000" ||
      strcnpj == "11111111111111" ||
      strcnpj == "22222222222222" ||
      strcnpj == "33333333333333" ||
      strcnpj == "44444444444444" ||
      strcnpj == "55555555555555" ||
      strcnpj == "66666666666666" ||
      strcnpj == "77777777777777" ||
      strcnpj == "88888888888888" ||
      strcnpj == "99999999999999") {
      return false
    }

    var tamanho = strcnpj.length - 2
    var numeros = strcnpj.substring(0, tamanho)
    var digitos = strcnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9
      }

    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0)) {
      return false
    }
    tamanho += 1
    numeros = strcnpj.substring(0, tamanho);
    soma = 0
    pos = tamanho - 7
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9
      }
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1)) {
      return false;
    }
    return true

  }
  //Validation first
  const validarPrimeiroDigito = cpf => {
    let sum = 0;
    var cpfstr = String(cpf)
    for (let i = 0; i < 9; i++) {
      sum += cpfstr[i] * (10 - i);
    }
    const resto = (sum * 10) % 11;
    if (resto < 10) {
      return cpfstr[9] == resto;
    }
    return cpfstr[9] == 0
  }
  //Validation second 
  const validarSegundoDigito = cpf => {
    let sum = 0;
    var cpfstr = String(cpf)
    for (let i = 0; i < 10; i++) {
      sum += cpfstr[i] * (11 - i);
    }
    const resto = (sum * 10) % 11;
    if (resto < 10) {
      return cpfstr[10] == resto;
    }
    return cpfstr[10] == 0;
  }
  // validation repeat
  const validarRepetido = cpf => {
    var cpfstr = String(cpf)
    const primeiro = cpfstr[0];
    let diferente = false;
    for (let i = 1; i < cpfstr.length; i++) {
      if (Number(cpfstr[i]) !== Number(primeiro)) {
        diferente = true;
      }
    }
    return diferente;
  }
  //validation 
  export const validarcpf = cpf => {
    if (!cpf)
      return cpf
    cpf = cpf.toString()
    var numcpf = this.onlyNumber(cpf)
    var strcpf = String(numcpf)
    if (!this.validarRepetido(numcpf) && strcpf.length == 11) {
      return false

    }
    if (!this.validarPrimeiroDigito(numcpf) && strcpf.length == 11) {

      return false

    }
    if (!this.validarSegundoDigito(numcpf) && strcpf.length == 11) {
      return false

    }
    return true
  }
  export const validarCpfeCnpj = value => {
    var teste1 = this.validarcpf(value)
    var teste2 = this.cnpjValidation(value)
    if (teste1 || teste2) {
      return true
    }

    return false
  }

  export const convertDecimalPlaces = value => {
    if (!value)
      return value
    value = value.toString()
    value = value.replace('.', ',')
    return value
  }