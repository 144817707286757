import axios from "axios";
import { toastOnError, toastOnSave, fixBlankProperties } from "../../utils/Utils";
import { onlyNumber } from "utils/mask";
import { GET_PRODUCTS, GET_PRODUCT, ADD_PRODUCT, DELETE_PRODUCT, UPDATE_PRODUCT, MODAL_ADD_PRODUCT, ADD_PRODUCT_SUPPLIER, GET_PRODUCT_SUPPLIERS, DELETE_PRODUCT_SUPPLIER, UPDATE_PRODUCT_SUPPLIER} from "./ProductTypes";
import { toast } from "react-toastify";

export const getProducts = (options = {is_message_to_user:true}) => dispatch => {
  let url = "/api/v1/products/";
  const params = [];
  if (options.supplier) {
    params.push(`supplier=${options.supplier}`);
  }
  if (options.customer)
    params.push(`customer=${options.customer}`)

  if (options.withStock)
    params.push(`withStock=${options.withStock}`)

  if (options.ncm_general) {
    params.push(`ncm_general=${options.ncm_general}`);
  }
  if(options.enabled){
    params.push(`enabled=${options.enabled}`);
  }

  if (params.length > 0) {
    url += "?" + params.join("&");
  }

  return axios
    .get(url)
    .then(response => {
      dispatch({
        type: GET_PRODUCTS,
        payload: response.data
      });
      if (options.is_message_to_user){
        toastOnSave("Pesquisa realizada com sucesso")
      }
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getProductSuppliers = (options = {is_message_to_user:true}) => dispatch => {

  let url = "/api/v1/productsupplier/"

  const params = [];
 
  if (options.customer)
    params.push(`customer=${options.customer}`)

  if (options.supplier)
    params.push(`supplier=${options.supplier}`)

  if (params.length > 0) {
    url += "?" + params.join("&");
  }

  return axios
    .get(url)
    .then(response => {
      dispatch({
        type: GET_PRODUCT_SUPPLIERS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const addProduct = company => dispatch => {
  return axios
    .post("/api/v1/products/", company)
    .then(response => {
      dispatch({
        type: ADD_PRODUCT,
        payload: response.data
      });
      toastOnSave("Inclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const deleteProduct = id => dispatch => {
  return axios
    .delete(`/api/v1/products/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_PRODUCT,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const deleteProductSupplier = id => dispatch => {
  return axios
    .delete(`/api/v1/productsupplier/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_PRODUCT_SUPPLIER,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getProduct = id => dispatch => {
  return axios
    .get(`/api/v1/products/${id}/`)
    .then(response => {
      dispatch({
        type: GET_PRODUCT,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const updateProduct = (id, company) => dispatch => {
  return axios
    .patch(`/api/v1/products/${id}/`, company)
    .then(response => {
      dispatch({
        type: UPDATE_PRODUCT,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const updateProductSupplier = (product_supplier) => dispatch => {
  return axios
    .post(`/api/v1/products/`, product_supplier)
    .then(response => {
      dispatch({
        type: UPDATE_PRODUCT,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
}
export const addProductSupplier = (product_supplier) => dispatch => {
  return axios
    .post("/api/v1/products/", product_supplier)
    .then(response => {
      dispatch({
        type: ADD_PRODUCT_SUPPLIER,
        payload: response.data
      });
      toastOnSave("Inclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
}
export const modalAddProduct = (product, supplier, productsupplier = null) => dispatch => {
    
  let prod = null
  if (!productsupplier) {
    if (!product.id) {
      prod = {
        name: '',
      }
    }
    else {
      prod = product
    }
    productsupplier = {
      product: prod,
      supplier: supplier
    }
  } else {
    prod = productsupplier
  }
  dispatch({
    type: MODAL_ADD_PRODUCT,
    supplier: supplier,
    product: product,
    product_supplier: productsupplier
  })
}
 