import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import ReportList from "../components/reports/ReportList";
import { Button, Container } from "react-bootstrap";

class Report extends Component {
  constructor() {
    super();
    this.state = {
      listing: true,
    };
  }

  openEdit = () => {
    this.props.history.push('/manage/movements/edit')
  }

  render() {
    const { listing } = this.state;
    return (
      <div>
        <Container>
          <ReportList />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const ListRepo= connect(mapStateToProps, {
})(withRouter(Report))

export { ListRepo }