import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import AddNf from "../components/nf/AddNf";
import NfList from "../components/nf/NfList"
import { Authorized } from "utils/AuthUtil";

class Nf extends Component {
    constructor() {
        super();
        this.state = {
            listing: true,
        };
    }

    render() {
        const { listing } = this.state;
        return (
            <div>
                <Container>
                    <NfList />
                    {/* <Authorized permissions={["operations.add_nf"]}>
                        <Link className="btn btn-primary position-sticky fixed-button" to="/manage/nfs/edit">
                            Retificar NF
                        </Link>
                    </Authorized> */}
                </Container>
            </div>
        );
    }
}

class CreateNf extends Component {
    constructor() {
        super();
    }

    render() {

        return (
            <div>
                <Container>
                    <AddNf />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const ListN = connect(mapStateToProps, {
})(withRouter(Nf))

const CreateN = connect(mapStateToProps, {
})(withRouter(CreateNf))

export { ListN, CreateN }