
import React, { Component } from "react";
import { Container } from "react-bootstrap";
import FloatLinks from "components/utils/FloatLinks";


class Footer extends Component {
  render() {
    return (
      <>
      <FloatLinks />
      <footer className="footer px-0 px-lg-3">
        <Container fluid>
        
          <nav>
            <ul className="footer-menu">
              <li>
                <a href="">
                  Início
                </a>
              </li>
              {/* <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Company
                </a>
              </li>
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Portfolio
                </a>
              </li>
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Blog
                </a>
              </li> */}
            </ul>
            <p className="copyright text-center">
              © {new Date().getFullYear()}{" "}
              <a href="">Alfa Química</a> - Todos os direitos reservados
            </p>
          </nav>
        </Container>
      </footer>
      </>
    );
  }
}

export default Footer;
