import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUserPersons, getEnabledUserPerson } from "./UserPersonsActions";
import { IMaskInput } from "react-imask";
import { PencilFill, TrashFill, Check2Circle, Search } from 'react-bootstrap-icons';
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';
import { formatDate } from "../../utils/Data";
import { header } from "../../utils/Datagrid";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { cnpjMask } from "utils/mask";
import Loader from "components/utils/Loader"
import {
  Card,
  Form,
  Row,
  Col,
  Button
} from "react-bootstrap";
import UserPerson from "./UserPerson";

class UserPersonsList extends Component {
  constructor(props) {
    super(props);
    this.dt = React.createRef();
    this.state = {
      up: { search: '0' },
      filters: {
        first_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        last_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        company_virtual: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        enabled_virtual: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      },
      loading: false
    }

  }

  componentDidMount() {
    this.setState({ loading: true })
    this.props.getUserPersons().then(() => {
    })
    .catch((error) => {})
    .finally(() => {
      this.setState({ loading: false })
    });
  }

  cols = [
    { field: 'first_name', header: 'Nome' },
    { field: 'last_name', header: 'Sobrenome' },
    { field: 'company_virtual', header: 'CNPJ/Razão social' },
    { field: 'enabled_virtual', header: 'Ativo' }
  ];

  exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));
  onChange = e => {
    var up = { ...this.state.up }
    this.setState({ loading: true })
    up[e.target.name] = e.target.value
    this.props.getEnabledUserPerson(this.state.up.search).then(() => {
      var ur = {...this.state}
      ur.up = up
      ur.loading = false
      this.setState(ur)
    })
    this.setState({ up });
  };
  dateBodyTemplate = (rowData) => {
    return formatDate(rowData.created_at);
  };

  alocBodyTemplate = (rowData) => {
    return <UserPerson key={rowData.id} userUserPerson={rowData} />
  }
  handleSubmit = () => {
    this.setState({ loading: true })
    this.props.getEnabledUserPerson(this.state.up.search).then(() => {
      this.setState({ loading: false })
    })
  }
  render() {
    const { userPersons } = this.props.userPersonsReduc;

    let items2 = userPersons.map(userUserPerson => {
      userUserPerson.enabled_virtual = userUserPerson.enabled ? "Sim" : "Não"
      userUserPerson.company_virtual = cnpjMask(userUserPerson.company_cnpj)  + " / " + userUserPerson.company_name
      return userUserPerson
    });

    return (
      <>
      <Loader loading={this.state.loading} />
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Cadastro de Usuários</Card.Title>
              <p className="card-category">
                Usuários
              </p>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <div className="card">
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable ref={this.dt} value={items2} header={(<div>
                  <Row className="mb-3"></Row>
                  <Row>
                    <Col md="10">
                      <Row >
                        <Form.Group as={Col} md="5" controlId="search">
                          <Form.Label className="font-weight-bold" style={{ color: 'black', fontSize: '16px' }}>Situação</Form.Label>
                          <Form.Control
                            as="select" required name="search" value={this.state.up.search}
                            onChange={this.onChange} maxLength={100} >
                            <option value='0'>Ativo</option>
                            <option value='1'>Inativo</option>
                          </Form.Control >
                          <Form.Control.Feedback>Ok</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Campo obrigatório.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    </Col>
                    <Col>
                      {header(userPersons, "Pessoas", this.dt, this.exportColumns)}
                    </Col>
                  </Row>
                </div>
                )}
                  tableStyle={{ minWidth: '50rem' }}
                  paginator rows={5}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  filterDisplay="row"
                  filters={this.state.filters}
                  emptyMessage="Não há pessoas cadastradas"

                >
                  {this.cols.map((col, index) => (
                    <Column key={index} filter sortable field={col.field} header={col.header} />
                  ))}
                  <Column body={this.alocBodyTemplate}></Column>
                </DataTable>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </>
    );
  }
}

UserPersonsList.propTypes = {
  getUserPersons: PropTypes.func.isRequired,
  userPersonsReduc: PropTypes.object.isRequired,
  getEnabledUserPerson: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userPersonsReduc: state.userPersonsReduc
});

export default connect(mapStateToProps, {
  getUserPersons, getEnabledUserPerson
})(withRouter(UserPersonsList));