import React from 'react';
import { IMaskInput } from "react-imask";
import {
    Form
} from "react-bootstrap";
import { onlyNumber} from "../../utils/mask"
import { toastOnError } from "../../utils/Utils";

class ControlPhone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone_mask: "(00) 0000-0000",
            loaded: false
        }
        // if (e.target.value.length > 14){
        //     this.state.phone_mask = "(00) 0 0000-0000"
        //     // this.setState({ phone_mask: "(00) 0 0000-0000" })
        // }
    }

    onChangePhone = (e) => {   
        this.props.onChangePhone(e);
    }

    onTypePhone = (e) => {
        if (this.state.loaded === false){
            this.setState({ loaded: true })
        }

        if (e.target.value.length === 14 && this.state.phone_mask === "(00) 0000-0000")
            this.setState({ phone_mask: "(00) 0 0000-0000" })        
        if (e.target.value.length === 16 && e.key === "Backspace" && this.state.phone_mask === "(00) 0 0000-0000"){            
            this.setState({ phone_mask: "(00) 0000-0000" })
        }
    }

    checkMask = (value) => {
        var mask = this.state.phone_mask
        if (this.state.loaded === false){
            var test = value
            if (value)
                test = onlyNumber(test)
            if (test && test.length > 10){
                mask = "(00) 0 0000-0000"
            }else{            
                mask = "(00) 0000-0000"
            }
        }
        return mask
    }

    render() {
        return (
            <>
            <Form.Label className="font-weight-bold">{this.props.label}</Form.Label>
            <Form.Control required={this.props.required} type="text" name={this.props.name} placeholder="(00) 0 0000-0000" value={this.props.value}
                onChange={this.onChangePhone} onKeyDown={this.onTypePhone} as={IMaskInput} mask={this.checkMask(this.props.value)} maxLength={16} />
            <Form.Control.Feedback>Ok</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
                Campo obrigatório.
            </Form.Control.Feedback>
            </>
        );
    }
}

export default ControlPhone;