import { GET_COMPANIES, GET_COMPANY, ADD_COMPANY, DELETE_COMPANY, UPDATE_COMPANY} from "./CompanyTypes";

const initialState = {
  companies: [],
  company:{}
};

export const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANIES:
      return {
        ...state,
        companies: action.payload
      };
    case GET_COMPANY:
      const companyUrl = action.payload.url || ''
      return {
        ...state,
        company: { ...action.payload, url: companyUrl }
      };
    case ADD_COMPANY:
      return {
        ...state,
        companies: [...state.companies, action.payload]
      };
    case DELETE_COMPANY:
      return {
        ...state,
        companies: state.companies.filter((item, index) => item.id !== action.payload)
      };
    case UPDATE_COMPANY:
      const updatedCompanies = state.companies.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        companies: updatedCompanies
      };
    default:
      return state;
  }
};