import React, { Component } from "react";
import { withRouter } from "react-router-dom";  // new import 
import { connect } from "react-redux";          // new import 
import PropTypes from "prop-types";             // new import 
import { Link } from "react-router-dom";
import { Container, Button, Row, Col, Form, Card, Figure} from "react-bootstrap";

import { resetPassword } from "components/login/LoginActions";
import { useTranslation, Trans, withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import requireAuth from "utils/RequireAuth.js";

class LoginUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      us:{
        current_password: "",
        new_password: "",
        re_new_password: ""
      },
      validated: false
    };
  }
  onChange = e => {
    var us = { ...this.state.us }
    us[e.target.name] = e.target.value        
    this.setState({ us });
  };

  setValidated = (v) => {
    this.setState({ validated: v });
  }

  handleSubmit = (event) => {
    const form = document.getElementById("updatePasswordForm")
    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }
    this.setValidated(true);
    if (form.checkValidity() !== false) {
        this.onLoginClick();
    }
};

  onLoginClick = () => {
    const userData = {
      current_password: this.state.us.current_password,
      new_password: this.state.us.new_password,
      re_new_password: this.state.us.re_new_password
    };
    this.props.resetPassword(userData, "/manage/persons").then(() => {}).catch(() => {});
  };
  render() {
    const { t, i18n } = this.props;
    return (
       <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                  <Card.Header>
                      <Card.Title as="h4" className="text-center text-secondary font-weight-bold ">Atualização de Senhas</Card.Title>
                  </Card.Header>
              <Card.Body>
              <Form id="updatePasswordForm" noValidate validated={this.state.validated}>
              <Card.Text>
                  <label>Senha atual:</label>
                  <Form.Control
                  required
                  type="password"
                  name="current_password"
                  placeholder="Senha atual"
                  value={this.state.us.current_password}
                  onChange={this.onChange}
                />
                <label>Nova senha:</label>
                <Form.Control
                  required
                  type="password"
                  name="new_password"
                  placeholder="Nova senha"
                  value={this.state.us.new_password}
                  onChange={this.onChange}
                />
                <label>Repita a senha:</label>
                <Form.Control
                  required
                  type="password"
                  name="re_new_password"
                  placeholder="Repita a senha"
                  value={this.state.us.re_new_password}
                  onChange={this.onChange}
                />
                        
              </Card.Text>
              <div className = "d-flex justify-content-center"> 
              <Button data-testid="btn-login" color="primary" onClick={this.handleSubmit}>
                Atualizar Senha
              </Button>
              </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    );
  }
}

// connect action and store and component
LoginUpdate.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  resetPassword
})(withRouter(withTranslation()(LoginUpdate)));