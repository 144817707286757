import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_USERPERSONS, GET_USERPERSON, ADD_USERPERSON, DELETE_USERPERSON, UPDATE_USERPERSON} from "./UserPersonsTypes";
import { toast } from "react-toastify";

export const getUserPersons = () => dispatch => {
  return axios
    .get("/api/v1/userpersons/")
    .then(response => {      
      dispatch({
        type: GET_USERPERSONS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const addUserPerson = userUserPerson => dispatch => {
  if (userUserPerson.user.passwordToCheck !== userUserPerson.user.password){
    
    return new Promise((resolve, reject) => {
        toast.error("As senhas não coincidem")
        reject('err')
    })
  }
  if (userUserPerson.user.emailToCheck !== userUserPerson.user.email){
    return new Promise((resolve, reject) => {
      toast.error("os Emails não coincidem")
      reject('err')
    })
  }

  return axios
    .post("/api/v1/userpersons/", userUserPerson)
    .then(response => {
      dispatch({
        type: ADD_USERPERSON,
        payload: response.data
      });
      toastOnSave("Inclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const deleteUserPerson = id => dispatch => {
  axios
    .delete(`/api/v1/userpersons/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_USERPERSON,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getUserPerson = id => dispatch => {
  axios
    .get(`/api/v1/userpersons/${id}/`)
    .then(response => {
      dispatch({
        type: GET_USERPERSON,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const updateUserPerson = (id, userUserPerson) => dispatch => {
  return axios
    .patch(`/api/v1/userpersons/${id}/`, userUserPerson)
    .then(response => {
      dispatch({
        type: UPDATE_USERPERSON,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const activateUserPerson = (id, userPerson) => dispatch => {
  return axios
    .patch(`/api/v1/userpersonsactivate/${id}/`, userPerson)
    .then(response => {
      dispatch({
        type: UPDATE_USERPERSON,
        payload: response.data
      });
      toastOnSave("Ativação realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};
export const getEnabledUserPerson = (enabled) => dispatch => {
  return axios
  .get(`/api/v1/userpersons/?enabled=${enabled}`)
  .then(response => {      
    dispatch({
      type: GET_USERPERSONS,
      payload: response.data
    });
  })
  .catch(error => {
    toastOnError(error);
  });
}
