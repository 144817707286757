import { GET_CALLS, GET_CALL, ADD_CALL, DELETE_CALL, UPDATE_CALL} from "./CallsTypes";

const initialState = {
    calls: [],
    call:{}
};

export const callsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CALLS:
      return {
        ...state,
        calls: action.payload
      };
    case GET_CALL:
      return {
        ...state,
        call: action.payload
      };
    case ADD_CALL:
      return {
        ...state,
        calls: [...state.calls, action.payload]
      };
    case DELETE_CALL:
      return {
        ...state,
        calls: state.calls.filter((item, index) => item.id !== action.payload)
      };
    case UPDATE_CALL:
      const updatedCall = state.calls.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        calls: updatedCall
      };
    default:
      return state;
  }
};