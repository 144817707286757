
import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loader from "components/utils/Loader"
import { withRouter, Link } from "react-router-dom";
import { addCall, updateCall, getCall, uploadCollect, getCalls } from "./CallsAction";
import { fileToSha256Hex } from "../../utils/sha256";
import { putBlankProperties, fixBlankProperties, toastOnError } from "../../utils/Utils";
import { FileEarmarkArrowDownFill } from 'react-bootstrap-icons';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    InputGroup,
    Row,
    Col,
    Modal,
    Table,
    Accordion
} from "react-bootstrap";

class AddCall extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            dc: {
                file_path: "",
                type: "",
                description: ""
            },
            validated: false,
            loading: false,
            selectedFiles: undefined,
            currentFile: undefined,
            progress: 0,
            message: "",
            fileInfos: [],
        }
        this.props.callsReduc.call = this.state.dc
        this.modalVerify()
    }

    setValidated = (v) => {
        this.setState({ validated: v });
    }

    modalVerify = () => {

    }

    handleSubmit = (event) => {
        const form = document.getElementById("addCallForm")
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setValidated(true);
        if (form.checkValidity() !== false) {

            this.onSaveClick();

        }
    };
    componentDidMount() {
        const { id } = this.props.match.params;
        if (id != null) {
            this.setState({ loading: true })
            this.props.getCall(id).then(() => {
                
            }).catch((err) => {
                
            }).finally(() => {
                this.setState({ loading: false })
            });
        }
        else {
            this.props.callsReduc.call = this.state.dc
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.callsReduc.call.id !== prevState.dc.id) {
            var { call } = nextProps.callsReduc;
            call = putBlankProperties(call)
            return {
                dc: call,
                validated: prevState.validated
            };
        }
        else

            return null
    }
    onChange = e => {
        var dc = { ...this.state.dc }
        dc[e.target.name] = e.target.value
        this.setState({ dc })

    };
    onChangeValue = (dc, e) => {
        dc[e.target.name] = e.target.value
        this.setState({ dc })
    }

    onSaveClick = (event) => {
        const call = {
            description: this.state.dc.description
        };


        if (!this.state.dc.id) {
            this.upload(call)
        }
        else {
            this.setState({ loading: true })
            var doc = { ...call }
            doc = fixBlankProperties(doc)
            this.props.updateCall(this.state.dc.id, doc).then(() => {
                this.props.history.push('/manage/calls')
                this.setState({ loading: false });
            })
            .catch((err) => {

            })
        }
    };
    selectFile = (event) => {
        this.setState({
            selectedFiles: event.target.files,
        });
    }

    handleCloseDialog = ()  => {
        this.props.onClose()
    }

    upload = async (call) => {
        if (!this.state.selectedFiles){
            toastOnError("Selecione um arquivo")
        }
        else{
            let currentFile = this.state.selectedFiles[0];
            this.setState({
                progress: 0,
                currentFile: currentFile,
            });
            const fn = (event) => {
                this.setState({
                    progress: Math.round((100 * event.loaded) / event.total),
                });
            }
            const hash = await fileToSha256Hex(currentFile)
            this.setState({ loading: true })
            this.props.uploadCollect(currentFile, hash, call, fn)
                .then(() => {
                    this.props.history.push('/manage/calls');
                })
                .catch(() => {
                    this.setState({
                        loading: false,
                        progress: 0,
                        message: "Arquivo não foi carregado!",
                        currentFile: undefined,
                    });
                });
        }
    }

    render() {
        const {
            selectedFiles,
            currentFile,
            progress,
            message,
            fileInfos,
        } = this.state;

        return (
            <>
            <Loader loading={this.state.loading}/>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card>
                            <Card.Header>
                                <Card.Title as="h4" className="text-center text-secondary font-weight-bold ">Chamado</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form id="addCallForm" noValidate validated={this.state.validated}>
                                    <Row>
                                        <Form.Group as={Col} md="8">
                                            <Form.Label className="font-weight-bold" >Descrição</Form.Label>
                                            <Form.Control
                                                required
                                                name="description"
                                                placeholder="Descrição"
                                                value={this.state.dc.description}
                                                onChange={this.onChange}
                                                maxLength={100}
                                            />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    
                                    {!this.props.callsReduc.call.id && <div>
                                        <div className="progress">
                                            <div
                                                className="progress-bar progress-bar-info progress-bar-striped"
                                                role="progressbar"
                                                aria-valuenow={progress}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{ width: progress + "%" }}
                                            >
                                                {progress}%
                                            </div>
                                        </div>

                                    </div>}
                                    {!this.props.callsReduc.call.id && <Form.Group as={Col} md="6" controlId="loadedImage">
                                        <Form.Label className="font-weight-bold">Arquivo: </Form.Label>
                                        <Form.Control type="file" accept="application/pdf, application/zip, image/png, image/jpeg, .xml" name="loadedImage" onChange={this.selectFile}
                                            style={{
                                                background: '#6C757D',
                                                width: '100%',
                                                height: '50%',
                                                color: 'white',
                                                padding: '2px',
                                                border: '2px solid #6C757D'
                                            }} />
                                    </Form.Group>}
                                    {this.props.callsReduc.call.file_path &&
                                        <>
                                            <Row>
                                                <Col md={1}>
                                                    <Form.Label className="font-weight-bold">Arquivo: </Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <a
                                                        target="_blank"
                                                        download
                                                        href={this.props.callsReduc.call.file_path}
                                                    >
                                                        <FileEarmarkArrowDownFill color="black" size={40} /></a>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </Form>
                            </Card.Body>
                        </Card>
                        <><Button className="btn btn-primary active" type="button" onClick={this.handleSubmit} >
                            Salvar
                        </Button> {' '}</>
                        {!this.props.operation &&
                            <Link to="/manage/calls/" className="btn btn-danger active">
                                Voltar
                            </Link>
                        }

                    </Col>
                </Row>
            </Container>
            </>
        )
    }


}
AddCall.propTypes = {    
    callsReduc: PropTypes.object.isRequired,
    addCall: PropTypes.func.isRequired,
    uploadCollect: PropTypes.func.isRequired,
    getCall: PropTypes.func.isRequired,
    updateCall: PropTypes.func.isRequired,
    getCalls: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
    callsReduc: state.callsReduc
});

export default connect(mapStateToProps, { addCall, getCalls, updateCall, getCall, uploadCollect,  })(withRouter(AddCall))