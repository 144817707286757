import React from 'react';
import { IMaskInput } from "react-imask";
import {
    Form
} from "react-bootstrap";
import { toastOnError } from "../../utils/Utils";

class ControlDensity extends React.Component {
    constructor(props) {
        super(props);
    }

    onChangeDensity = (e) => {
        if (e.target.value[0] === ","){
            toastOnError("Utilize vírgula apenas como separador decimal")
            e.target.value = ""
        }
        this.props.onChangeDensity(e);
    }

    render() {
        return (
            <>
            <Form.Label className="font-weight-bold">Densidade</Form.Label>
            <Form.Control 
            required
            type='text' name="density"
            onChange={this.onChangeDensity} 
            placeholder="00,00"
            as={IMaskInput} mask={Number}
            scale={2} radix="," mapToRadix = {['.']} 
            thousandsSeparator = "" padFractionalZeros = {true} min={0} max={99.99}
            normalizeZeros = {false}
            value={this.props.value}
            autoComplete="off"
            maxLength={5}/>
            <Form.Control.Feedback>Ok</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
                Campo obrigatório.
            </Form.Control.Feedback>
            </>
        );
    }
}

export default ControlDensity;