import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_PERSONS, GET_PERSON, ADD_PERSON, DELETE_PERSON, UPDATE_PERSON} from "./PersonsTypes";

export const getPersons = () => dispatch => {
  return axios
    .get("/api/v1/persons/")
    .then(response => {      
      dispatch({
        type: GET_PERSONS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getProfileByLoggedUser = (loggedUser) => dispatch => {
  return axios
    .get("/api/v1/persons/?user="+loggedUser.username)
    .then(response => {      
      dispatch({
        type: GET_PERSONS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const addPerson = person => dispatch => {
  return axios
    .post("/api/v1/persons/", person)
    .then(response => {
      dispatch({
        type: ADD_PERSON,
        payload: response.data
      });
      toastOnSave("Inclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const deletePerson = id => dispatch => {
  axios
    .delete(`/api/v1/persons/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_PERSON,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getPerson = id => dispatch => {
  axios
    .get(`/api/v1/persons/${id}/`)
    .then(response => {
      dispatch({
        type: GET_PERSON,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const updatePerson = (id, person) => dispatch => {
  return axios
    .patch(`/api/v1/persons/${id}/`, person)
    .then(response => {
      dispatch({
        type: UPDATE_PERSON,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};