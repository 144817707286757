import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import AddCall from "../components/calls/AddCall";
import CallsList from "../components/calls/CallsList";
import { Authorized } from "utils/AuthUtil";
import { Button, Container } from "react-bootstrap";

class Call extends Component {
  constructor() {
    super();
    this.state = {
      listing: true,
    };
  }



  openEdit = () => {
    this.props.history.push('/manage/calls/edit')
  }

  render() {
    const { listing } = this.state;
    return (
      <div>
        <Container>
          <CallsList />
          <Authorized permissions={["operations.add_call"]}>
            <Button icon="pi pi-times" onClick={this.openEdit} className="btn btn-primary position-sticky fixed-button">
              Adicionar
            </Button>{' '}
          </Authorized>
        </Container>
      </div>
    );
  }
}

class CreateCall extends Component {
  constructor() {
    super();
  }


  render() {

    return (
      <div >
        <Container className="float-start">
          <AddCall />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const ListCa = connect(mapStateToProps, {
})(withRouter(Call))

const CreateCa = connect(mapStateToProps, {
})(withRouter(CreateCall))

export { ListCa, CreateCa }