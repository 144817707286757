import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_DESTINATIONS, GET_DESTINATION, ADD_DESTINATION, DELETE_DESTINATION, UPDATE_DESTINATION} from "./DestinationTypes";
import { toast } from "react-toastify";

export const getDestinations = (options={cnpj:null} ) => dispatch => {
  var url = "/api/v1/destinations/"
  var complement = []
  if (options.cnpj){
    complement.push(`cnpj=${options.cnpj}`)
  }

  if (complement.length > 0){
    url += "?" + complement.join("&")
  }

  return axios
    .get(url)
    .then(response => {      
      dispatch({
        type: GET_DESTINATIONS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const addDestination = destination => dispatch => {
  return axios
    .post("/api/v1/destinations/", destination)
    .then(response => {
      dispatch({
        type: ADD_DESTINATION,
        payload: response.data
      });
      toastOnSave("Inclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const deleteDestination = id => dispatch => {
  axios
    .delete(`/api/v1/destinations/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_DESTINATION,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getDestination = id => dispatch => {
  axios
    .get(`/api/v1/destinations/${id}/`)
    .then(response => {
      dispatch({
        type: GET_DESTINATION,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const updateDestination = (id, destination) => dispatch => {
  return axios
    .patch(`/api/v1/destinations/${id}/`, destination)
    .then(response => {
      dispatch({
        type: UPDATE_DESTINATION,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};