import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { deleteFpFile, updateFpFile } from "./FpFileActions";
import { Button } from "react-bootstrap";
import { PencilFill, TrashFill, Download} from 'react-bootstrap-icons';
import { Authorized } from "utils/AuthUtil";
import ConfirmationModal from "components/utils/ConfirmationModal";

class FpFile extends Component {

  onDeleteClick = () => {
    const { fpFile } = this.props;
    this.props.deleteFpFile(fpFile.id);
  };

  onEditClick = (fpFile) => {
    if (!this.props.isModalAddFpFile)
      this.props.history.push(`/manage/fpFiles/edit/${fpFile.id}`);
  }

  onDownload = () => {
    const { fpFile } = this.props;

    const blob = new Blob([fpFile.file_content], { type: 'text/plain' });
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = fpFile.file_name || 'download.txt';
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }

  render() {
    const { fpFile } = this.props;
    return (      
      <div className="btn-group">        
        <Button className="btn btn-primary" onClick={() => this.onEditClick(fpFile)}>
          <PencilFill color="royalblue" />
        </Button>
        <Button variant="success" className="btn btn-primary" onClick={this.onDownload}>
          <Download color="green" />
        </Button>
        <Authorized>
          <ConfirmationModal title="Deseja inativar este registro" function={this.onDeleteClick} />
        </Authorized>
      </div>      
    );
  }
}

FpFile.propTypes = {
  fpFile: PropTypes.object.isRequired
  // modalAddFpFile: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  isModalAddFpFile: state.fpFilesReduc.isModalAddFpFile
});

export default connect(mapStateToProps, { deleteFpFile, updateFpFile
  // modalAddFpFile 
})(
  withRouter(FpFile)
);