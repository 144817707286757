
import React, { Component } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import {dashboardRoutes as routes} from "routes.js";
import { nestedRoutes } from "routes.js";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logout } from "../login/LoginActions";
import { useTranslation, Trans, withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { checkAuth } from "utils/AuthUtil";
import { getUserPersons } from "../userPerson/UserPersonsActions";
import { cnpjMask } from "utils/mask";
import { getProfileByLoggedUser } from "../persons/PersonsActions";

class Header extends Component {
  constructor(props, context) {
    super(props, context);
  
    this.state = {
      el: {
        company: ""
      }
    }
    this.fetchUserProfile();

    }
  onLogout = () => {
    this.props.logout();
  };

  location = '/manage/dashboard'
  fetchUserProfile = () => {
    this.props.getProfileByLoggedUser(this.props.auth.user)
      .then(() => {
        const {persons} = this.props.personsReduc;
        this.setState(prevState => ({
          el: {
            ...prevState.el,
            company: persons && persons.length > 0 ? cnpjMask(persons[0].company_cnpj) + ' - ' + persons[0].company_name : ''
          }
        }));
      
    }  )
  }
  getBrandText = (user) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1 && this.isAuth(user, routes[i])) {
        return routes[i].name;
      }
    }
  };

  isAuth(user, prop){
    return (checkAuth(prop.auth, user))
  }

  getBrandName = (user) => {
    for (let i = 0; i < nestedRoutes.length; i++) {
      if (location.pathname.indexOf(nestedRoutes[i].layout + nestedRoutes[i].path) !== -1 && this.isAuth(user, nestedRoutes[i])) {
        return nestedRoutes[i].name;
      }
    }
  }

  render() {
    const { user } = this.props.auth;
    const {persons} = this.props.personsReduc;
    const company = persons && persons.length > 0 && persons[0].company_cnpj ? cnpjMask(persons[0].company_cnpj) + ' - ' + persons[0].company_name : ''
        
    const { t, i18n } = this.props;
    const lngs = {
      en: { nativeName: 'English' },
      de: { nativeName: 'Deutsch' },
      pt: { nativeName: 'Português' }
    };
    const getGreetingTime = (d = DateTime.now()) => {
      const split_afternoon = 12; // 24hr time to split the afternoon
      const split_evening = 17; // 24hr time to split the evening
      // const currentHour = parseFloat(d.toFormat('hh'));
      const currentHour = parseFloat(d.hour);
      if (currentHour >= split_afternoon && currentHour <= split_evening) {
        return 'afternoon';
      } else if (currentHour >= split_evening) {
        return 'evening';
      }
      return 'morning';
    }
    
    const mobileSidebarToggle = (e) => {
      e.preventDefault();
      document.documentElement.classList.toggle("nav-open");
      var node = document.createElement("div");
      node.id = "bodyClick";
      node.onclick = function () {
        this.parentElement.removeChild(this);
        document.documentElement.classList.toggle("nav-open");
      };
      document.body.appendChild(node);
    };
    
    return (
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
            <Button
              variant="dark"
              className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
              onClick={mobileSidebarToggle}
            >
              <i className="fas fa-ellipsis-v"></i>
            </Button>
            <Navbar.Brand
              href="#home"
              onClick={(e) => e.preventDefault()}
              className="mr-2"
            >
              {this.getBrandName(user)}
              {this.getBrandText(user)}
            </Navbar.Brand>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            {/* <Nav className="nav mr-auto" navbar>
              <Nav.Item>
                <Nav.Link
                  data-toggle="dropdown"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="m-0"
                >
                  <i className="nc-icon nc-palette"></i>
                  <span className="d-lg-none ml-1">Dashboard</span>
                </Nav.Link>
              </Nav.Item>
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  data-toggle="dropdown"
                  id="dropdown-67443507"
                  variant="default"
                  className="m-0"
                >
                  <i className="nc-icon nc-planet"></i>
                  <span className="notification">5</span>
                  <span className="d-lg-none ml-1">Notification</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Notification 1
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Notification 2
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Notification 3
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Notification 4
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Another notification
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Nav.Item>
                <Nav.Link
                  className="m-0"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="nc-icon nc-zoom-split"></i>
                  <span className="d-lg-block"> Search</span>
                </Nav.Link>
              </Nav.Item>
            </Nav> */}
            <Nav className="ml-auto" navbar>
              {/* <Nav.Item>
                <Nav.Link
                  className="m-0"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <span className="no-icon">Account</span>
                </Nav.Link>
              </Nav.Item>            
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  aria-expanded={false}
                  aria-haspopup={true}
                  as={Nav.Link}
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  variant="default"
                  className="m-0"
                >
                  <span className="no-icon">Dropdown</span>
                </Dropdown.Toggle>
                <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Action
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Something
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Something else here
                  </Dropdown.Item>
                  <div className="divider"></div>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Separated link
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            <Nav.Item>
              {/* <div>
                {Object.keys(lngs).map((lng) => (
                  <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                    {lngs[lng].nativeName}
                  </button>
                ))}
              </div> */}
              <Nav.Link className="m-0">{t('header.date', { date: new Date(), context: getGreetingTime() })}</Nav.Link>
            </Nav.Item>
            <Nav.Item >
              <Nav.Link className="m-0"> CNPJ: <b>{company}</b></Nav.Link>
            </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="m-0"
                  href="#pablo"
                  onClick={this.onLogout}>                
                  <span className="no-icon"> Usuário: <b>{user.username}</b>  Sair</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  };
}

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  getProfileByLoggedUser: PropTypes.func.isRequired,
  personsReduc:PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  personsReduc: state.personsReduc, 
});

export default connect(mapStateToProps, {
  logout, getUserPersons,getProfileByLoggedUser
})(withRouter(withTranslation()(Header)));