import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_NCMSYNONYMS, GET_NCMSYNONYM, ADD_NCMSYNONYM, DELETE_NCMSYNONYM, UPDATE_NCMSYNONYM} from "./NCMSynonymTypes";
import { toast } from "react-toastify";

export const getNCMSynonyms = () => dispatch => {
  axios
    .get("/api/v1/ncmsynonyms/")
    .then(response => {      
      dispatch({
        type: GET_NCMSYNONYMS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getNCMSynonymsByNCMGeneral = (ncmgeneral) => dispatch => {
  return axios
    .get(`/api/v1/ncmsynonyms/?ncm_general=${ncmgeneral}`)
    .then(response => {      
      dispatch({
        type: GET_NCMSYNONYMS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const addNCMSynonym = company => dispatch => {
  return axios
    .post("/api/v1/ncmsynonyms/", company)
    .then(response => {
      dispatch({
        type: ADD_NCMSYNONYM,
        payload: response.data
      });
      toastOnSave("Inclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const deleteNCMSynonym = id => dispatch => {
  axios
    .delete(`/api/v1/ncmsynonyms/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_NCMSYNONYM,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getNCMSynonym = id => dispatch => {
  axios
    .get(`/api/v1/ncmsynonyms/${id}/`)
    .then(response => {
      dispatch({
        type: GET_NCMSYNONYM,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const updateNCMSynonym = (id, company) => dispatch => {
  return axios
    .patch(`/api/v1/ncmsynonyms/${id}/`, company)
    .then(response => {
      dispatch({
        type: UPDATE_NCMSYNONYM,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};