import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import AddPerson from "../components/persons/AddPerson";
import PersonsList from "../components/persons/PersonsList";
import LoginUpdate from "../components/persons/LoginUpdate"

class LgnUpdate extends Component {
    constructor() {
        super();
    }

    render() {

        return (    
            <div>
                <Container>
                    <LoginUpdate />
                </Container>
            </div>
        );
    }
}

class Person extends Component {
    constructor() {
        super();
        this.state = {
            listing: true,
        };
    }

    render() {
        const { listing } = this.state;
        const { persons } = this.props.personsReduc;
        return (
            <div>
                <Container>
                    <PersonsList />
                    {(!persons || persons.length == 0) && 
                        <Link className="btn btn-primary position-sticky fixed-button" to="/manage/persons/edit">
                            Adicionar Perfil
                        </Link>
                     }
                    {' '}
                    <Link className="btn btn-danger position-sticky fixed-button" to="/manage/persons/updateuserpwd/">
                        Atualizar Senha
                    </Link>
                </Container>
            </div>
        );
    }
}

class CreatePerson extends Component {
    constructor() {
        super();
    }

    render() {

        return (
            <div>
                <Container>
                    <AddPerson />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    personsReduc: state.personsReduc
});

const ListPe = connect(mapStateToProps, {
})(withRouter(Person))

const CreatePe = connect(mapStateToProps, {
})(withRouter(CreatePerson))

const setUserPwd = connect(mapStateToProps, {
})(withRouter(LgnUpdate))

export { ListPe, CreatePe, setUserPwd }