import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_ORIGINS, GET_ORIGIN, ADD_ORIGIN, DELETE_ORIGIN, UPDATE_ORIGIN} from "./OriginTypes";
import { toast } from "react-toastify";

export const getOrigins = (options={cnpj:null}) => dispatch => {
  var url = "/api/v1/origins/"
  var complement = []
  if (options.cnpj){
    complement.push(`cnpj=${options.cnpj}`)
  }

  if (complement.length > 0){
    url += "?" + complement.join("&")
  }

  return axios
    .get(url)
    .then(response => {      
      dispatch({
        type: GET_ORIGINS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const addOrigin = origin => dispatch => {
  return axios
    .post("/api/v1/origins/", origin)
    .then(response => {
      dispatch({
        type: ADD_ORIGIN,
        payload: response.data
      });
      toastOnSave("Inclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const deleteOrigin = id => dispatch => {
  axios
    .delete(`/api/v1/origins/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_ORIGIN,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getOrigin = id => dispatch => {
  axios
    .get(`/api/v1/origins/${id}/`)
    .then(response => {
      dispatch({
        type: GET_ORIGIN,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const updateOrigin = (id, origin) => dispatch => {
  return axios
    .patch(`/api/v1/origins/${id}/`, origin)
    .then(response => {
      dispatch({
        type: UPDATE_ORIGIN,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};