import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import AddUserPerson from "../components/userPerson/AddUserPerson";
import UserPersonsList from "../components/userPerson/UserPersonsList";

class UserPerson extends Component {
    constructor() {
        super();
        this.state = {
            listing: true,
        };
    }

    render() {
        const { listing } = this.state;
        return (
            <div>
                <Container>
                    <UserPersonsList />
                
                        <Link className="btn btn-primary position-sticky fixed-button" to="/manage/userpersons/edit">
                            Adicionar Usuário
                        </Link>
                    
                </Container>
            </div>
        );
    }
}

class CreatePerson extends Component {
    constructor() {
        super();
    }

    render() {

        return (
            <div>
                <Container>
                    <AddUserPerson />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const ListUPe = connect(mapStateToProps, {
})(withRouter(UserPerson))

const CreateUPe = connect(mapStateToProps, {
})(withRouter(CreatePerson))

export { ListUPe, CreateUPe }