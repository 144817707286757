import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_ACQUIRERS, GET_ACQUIRER, ADD_ACQUIRER, DELETE_ACQUIRER, UPDATE_ACQUIRER} from "./AcquirerTypes";
import { toast } from "react-toastify";

export const getAcquirers = (options={cnpj:null}) => dispatch => {

  var url = "/api/v1/acquirers/"
  var complement = []
  if (options.cnpj){
    complement.push(`cnpj=${options.cnpj}`)
  }

  if (complement.length > 0){
    url += "?" + complement.join("&")
  }

  return axios
    .get(url)
    .then(response => {      
      dispatch({
        type: GET_ACQUIRERS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const addAcquirer = acquirer => dispatch => {
  return axios
    .post("/api/v1/acquirers/", acquirer)
    .then(response => {
      dispatch({
        type: ADD_ACQUIRER,
        payload: response.data
      });
      toastOnSave("Inclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const deleteAcquirer = id => dispatch => {
  axios
    .delete(`/api/v1/acquirers/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_ACQUIRER,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getAcquirer = id => dispatch => {
  axios
    .get(`/api/v1/acquirers/${id}/`)
    .then(response => {
      dispatch({
        type: GET_ACQUIRER,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const updateAcquirer = (id, acquirer) => dispatch => {
  return axios
    .patch(`/api/v1/acquirers/${id}/`, acquirer)
    .then(response => {
      dispatch({
        type: UPDATE_ACQUIRER,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};