import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_STOCKS, GET_STOCK, ADD_STOCK, DELETE_STOCK, UPDATE_STOCK} from "./StockTypes";
import { toast } from "react-toastify";

export const getStocks = (company) => dispatch => {

  var url = "/api/v1/stocks/"
  var complement = []  
  if (company)
    complement.push(`customer=${company}`)

  if (complement.length > 0){
    url += "?" + complement.join("&")
  }

  return axios
    .get(url)
    .then(response => {      
      dispatch({
        type: GET_STOCKS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const addStock = stock => dispatch => {
  return axios
    .post("/api/v1/stocks/", stock)
    .then(response => {
      dispatch({
        type: ADD_STOCK,
        payload: response.data
      });
      toastOnSave("Inclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const addStockInBatch = stock => dispatch => {
  return axios
    .post("/api/v1/nfproducts/", stock)
    .then(response => {
      dispatch({
        type: ADD_STOCK,
        payload: response.data
      });
      toastOnSave("Nota fiscal importada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const deleteStock = id => dispatch => {
  axios
    .delete(`/api/v1/stocks/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_STOCK,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getStock = id => dispatch => {
  return axios
    .get(`/api/v1/stocks/${id}/`)
    .then(response => {
      dispatch({
        type: GET_STOCK,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const updateStock = (id, stock) => dispatch => {
  return axios
    .patch(`/api/v1/stocks/${id}/`, stock)
    .then(response => {
      dispatch({
        type: UPDATE_STOCK,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};