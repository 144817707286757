import de from './de/translation.json';
import en from './en/translation.json';
import pt from './pt/translation.json';

//https://locize.com/blog/react-i18next/
export const res = () => {
    var x = {
        "en": {
            "translation" : en
        },
        "de": {
            "translation" : de
        },
        "pt": {
            "translation" : pt
        }
    }
    return x;
}