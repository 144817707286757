import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import AddFpFile from "../components/fpfile/AddFpFile";
import FpFileList from "../components/fpfile/FpFileList"

class FpFile extends Component {
    constructor() {
        super();
        this.state = {
            listing: true,
        };
    }

    render() {
        const { listing } = this.state;
        return (
            <div>
                <Container>
                    <FpFileList />
                  
                        <Link className="btn btn-primary position-sticky fixed-button" to="/manage/fpfiles/edit">
                            Novo
                        </Link> {' '}
                  
                </Container>
            </div>
        );
    }
}

class CreateFpFile extends Component {
    constructor() {
        super();
    }

    render() {

        return (
            <div>
                <Container>
                    <AddFpFile />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const ListFP = connect(mapStateToProps, {
})(withRouter(FpFile))

const CreateFP = connect(mapStateToProps, {
})(withRouter(CreateFpFile))

export { ListFP, CreateFP }