import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import ReportList from './ReportList';

class ReportModal extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      showList: this.props.show,
      showAddCall: false,
    }

  }

 
  
  render() {

    return (

      <div>
       
        <Dialog visible={this.props.showModal } onHide={this.props.onCloseModal} size="xl" 
          style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} >
            <ReportList onCloseModal={this.props.onCloseModal}/>
        </Dialog>
      </div>

    )
  };
};

export default ReportModal;
