import { GET_ACQUIRERS, GET_ACQUIRER, ADD_ACQUIRER, DELETE_ACQUIRER, UPDATE_ACQUIRER} from "./AcquirerTypes";

const initialState = {
  acquirers: [],
  acquirer:{}
};

export const acquirersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACQUIRERS:
      return {
        ...state,
        acquirers: action.payload
      };
    case GET_ACQUIRER:
      return {
        ...state,
        acquirer: action.payload
      };
    case ADD_ACQUIRER:
      return {
        ...state,
        acquirers: [...state.acquirers, action.payload]
      };
    case DELETE_ACQUIRER:
      return {
        ...state,
        acquirers: state.acquirers.filter((item, index) => item.id !== action.payload)
      };
    case UPDATE_ACQUIRER:
      const updatedAcquirers = state.acquirers.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        acquirers: updatedAcquirers
      };
    default:
      return state;
  }
};