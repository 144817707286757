import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { PencilFill, TrashFill, Check2Circle } from 'react-bootstrap-icons';
import { Modal } from "react-bootstrap";
import { deleteCall, updateCall } from "./CallsAction";
import { Authorized } from "utils/AuthUtil";
import ConfirmationModal from "components/utils/ConfirmationModal";

class Call extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
    }

  }
  onDeleteClick = () => {
    const { call } = this.props;
    this.props.deleteCall(call.id);
  }

  onRemoveClick = () => {
    this.setState({
      isDeleting: true
    })
  }
  onCancelClick = () => {
    this.setState({
      isDeleting: false,
    })
  }
  
  render() {
    const { call } = this.props
    return (
      <div className="btn-group">
        <Authorized permissions={["operations.change_call"]}>
          <Link to={`/manage/calls/edit/${call.id}`} role="button" className="btn btn-primary">
            <PencilFill color="royalblue" />
          </Link>
        </Authorized>
        <ConfirmationModal title="Deseja inativar este chamado" function={this.onDeleteClick} />
      </div>
    )
  }
}
Call.propTypes = {
  call: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  callsReduc: state.callsReduc
});

export default connect(mapStateToProps, { updateCall, deleteCall })(
  withRouter(Call)
);