import { GET_ORIGINS, GET_ORIGIN, ADD_ORIGIN, DELETE_ORIGIN, UPDATE_ORIGIN} from "./OriginTypes";

const initialState = {
  origins: [],
  origin:{}
};

export const originsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORIGINS:
      return {
        ...state,
        origins: action.payload
      };
    case GET_ORIGIN:
      return {
        ...state,
        origin: action.payload
      };
    case ADD_ORIGIN:
      return {
        ...state,
        origins: [...state.origins, action.payload]
      };
    case DELETE_ORIGIN:
      return {
        ...state,
        origins: state.origins.filter((item, index) => item.id !== action.payload)
      };
    case UPDATE_ORIGIN:
      const updatedOrigins = state.origins.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        origins: updatedOrigins
      };
    default:
      return state;
  }
};