import {Buffer} from 'buffer';
  
  export function arrayBufferToBuffer(ab) {
    var buffer = new Buffer(ab.byteLength)
    var view = new Uint8Array(ab)
    for (var i = 0; i < buffer.length; ++i) {
      buffer[i] = view[i]
    }
    return buffer
  }
  
  export function hexString(buffer) {
    const byteArray = new Uint8Array(buffer)
    const hexCodes = [...byteArray].map(value => {
      return value.toString(16).padStart(2, '0')
    })
  
    return '0x'+hexCodes.join('')
  }
  
  export async function fileToArrayBuffer (file) {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader()
      const readFile = function(event) {
        const buffer = reader.result
        resolve(buffer)
      }
  
      reader.addEventListener('load', readFile)
      reader.readAsArrayBuffer(file)
    })
  }
  
  export async function bufferToSha256 (buffer) {
    return window.crypto.subtle.digest('SHA-256', buffer)
  }
  
  export async function fileToSha256Hex (file) {
    const buffer = await fileToArrayBuffer(file)
    const hash = await bufferToSha256(arrayBufferToBuffer(buffer))
    return hexString(hash)
  }
  
  // usage
//   const file = fileInput.files[0]
//   const hash = await fileToSha256Hex(file)
//   console.log(hash)