import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_UF, GET_UFS } from "./UfsTypes";

export const getUfs = () => dispatch => {
  return axios
    .get("/api/v1/ufs/")
    .then(response => {      
      dispatch({
        type: GET_UFS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getUf = id => dispatch => {
  axios
    .get(`/api/v1/ufs/${id}/`)
    .then(response => {
      dispatch({
        type: GET_UF,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};