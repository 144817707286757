


export const checkAuth  = (objAuth, user) => {
    return (!objAuth || (user && (user['is_superuser'] || (user['all_permissions'] && user['all_permissions'].includes(objAuth)))))
}

export const Authorized = ({ login, permissions, children }) => {
    const user = getUser();
    const loginMatch = login && user.username === login;
    const rolesMatch = permissions && permissions.some((r) => user.all_permissions.includes(r));
    return Boolean(loginMatch || rolesMatch || user.is_superuser) && children;
};

export const getUser = () => {
    const user = JSON.parse(localStorage.getItem("user"))
    return user
}

export const checkUserAndAuth = (objAuth) => {
    const user = this.getUser()
    return checkAuth(objAuth, user)
}