import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getProfileByLoggedUser } from "./PersonsActions";
import { IMaskInput } from "react-imask";
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';
import {  formatDate } from "../../utils/Data";
import { header } from "../../utils/Datagrid";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import Loader from "components/utils/Loader"

import Person from "./Person";
import {
  Card,
  Form,
  Row,
  Col,
} from "react-bootstrap";

class PersonsList extends Component {
  constructor(props) {
    super(props);    
    this.dt = React.createRef();
    this.state = {
      loading:false
    }
  }

  componentDidMount() {
    const { user } = this.props.auth;
    this.setState({ loading: true })
    this.props.getProfileByLoggedUser(user).then(() => {
    })
    .catch((error) => {})
    .finally(() => {
      this.setState({ loading: false })
    });
  }

  cols = [
    { field: 'first_name', header: 'Nome' },
    { field: 'last_name', header: 'Sobrenome' },
    { field: 'company_name', header: 'Nome Gestora' },
    { field: 'company_cnpj', header: 'CNPJ Gestora' },
  ];

  exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));

  alocBodyTemplate = (rowData) => {
    return <Person key={rowData.id} person={rowData} />
  }

  render() {
    const { persons } = this.props.personsReduc;

    let items2 = persons.map(person => {
      return person
    });

    return (
      <>
      <Loader loading={this.state.loading} />
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Meu perfil</Card.Title>
              <p className="card-category">
                {/* Meu Perfil */}
              </p>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <div className="card">
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable ref={this.dt} value={items2} 
                  emptyMessage="Não há perfil cadastrado"
                  >
                  {this.cols.map((col, index) => (
                      <Column key={index} field={col.field} header={col.header} />
                  ))}
                  <Column body={this.alocBodyTemplate}></Column>
                </DataTable>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </>
    );
  }
}

PersonsList.propTypes = {
  getProfileByLoggedUser: PropTypes.func.isRequired,
  personsReduc: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  personsReduc: state.personsReduc,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getProfileByLoggedUser
})(withRouter(PersonsList));