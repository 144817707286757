import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Button, Row, Col, Form, Card, Figure} from "react-bootstrap";
import { resetForgotPassword} from "./LoginActions.js";
import { useTranslation, Trans, withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import requireAuth from "utils/RequireAuth.js";
import Loader from "components/utils/Loader"

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      us: {
            uid: "",
            token: "",
            new_password: "",
            re_new_password: ""
          },
          loading: false,
          validated:false,
    };
  }
  onChange = e => {
    var {us} = this.state
    us[e.target.name] = e.target.value       
    this.setState({ us });
  };



  setValidated = (v) => {
    this.setState({ validated: v });
  }

  handleSubmit = (event) => {
    const form = document.getElementById("passwordResetForm")    
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setValidated(true);    
    if (form.checkValidity() !== false){
      this.onResetClick();
    }    
  };

  onResetClick = () => {   
    
    const query = new URLSearchParams(this.props.location.search);
    const uid = query.get("uid");
    const token = query.get("token")
    var {us} = this.state
    us.uid = uid
    us.token = token
    
    this.setState({loading:true})
    this.props.resetForgotPassword(us).then(() => {
        this.setState({loading:false})
        this.props.history.push('/login');
        }
    )
    .catch(error => {
        this.setState({loading:false})
      }
    )
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <>
      <Loader loading={this.state.loading} />
      <div>
      <Row>
        <Col md='4'></Col>
        <Col md='4'>
          <Card className="" >
            <Card.Body>
              <Form id="passwordResetForm" noValidate validated={this.state.validated}>
              <Card.Text>
                  <label>Senha:</label>
                <Form.Control
                  type="password"
                  required
                  name="new_password"
                  placeholder="Senha"
                  value={this.state.us.new_password}
                  onChange={this.onChange}
                />
                        
              </Card.Text>

              <Card.Text>
                  <label>Repita a Senha:</label>
                <Form.Control
                  type="password"
                  required
                  name="re_new_password"
                  placeholder="Repita a Senha"
                  value={this.state.us.re_new_password}
                  onChange={this.onChange}
                />
                        
              </Card.Text>

              <div className = "d-flex justify-content-center"> 
              <Button data-testid="btn-login" color="primary" onClick={this.handleSubmit}>
              Resetar
              </Button>
              </div>
              <label>Não Possui cadastro? <Card.Link href= "/signup">Novo cadastro</Card.Link></label> 
              <label>Já possui? <Card.Link href= "/login">Login</Card.Link></label>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md='4'></Col>
      </Row>
      </div>
      </>
    );
  }
}

// connect action and store and component
PasswordReset.propTypes = {
  auth: PropTypes.object.isRequired,
  resetForgotPassword: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  resetForgotPassword
})(withRouter(withTranslation()(PasswordReset)));