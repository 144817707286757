import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { deleteStock, updateStock } from "./StockActions";
import { Button } from "react-bootstrap";
import { PencilFill, TrashFill, Check2Circle} from 'react-bootstrap-icons';

class Stock extends Component {

  onDeleteClick = () => {
    const { stock } = this.props;
    this.props.deleteStock(stock.id);
  };
  onEditClick = () => {
    
  }

  onUpperCaseClick = () => {
    const { stock } = this.props;
    this.props.updateStock(stock.id, {
      content: stock.content.toUpperCase()
    });
  };
  onLowerCaseClick = () => {
    const { stock } = this.props;
    this.props.updateStock(stock.id, {
      content: stock.content.toLowerCase()
    });
  };

  render() {
    const { stock } = this.props;
    return (      
      <div className="btn-group">        
        <Link to={`/manage/stocks/edit/${stock.id}`} role="button" className="btn btn-primary">          
          <PencilFill color="royalblue" />
        </Link>
        <Button variant="danger" className="btn btn-primary" onClick={this.onDeleteClick}>
          <TrashFill color="red" />
        </Button>
      </div>      
    );
  }
}

Stock.propTypes = {
    stock: PropTypes.object.isRequired
};
const mapStateToProps = state => ({});

export default connect(mapStateToProps, { deleteStock, updateStock })(
  withRouter(Stock)
);