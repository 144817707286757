import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { deleteMovement, updateMovement } from "./MovementActions";
import { Button } from "react-bootstrap";
import { PencilFill, TrashFill, Check2Circle} from 'react-bootstrap-icons';
import { getStock } from "../stock/StockActions"
import { Authorized } from "utils/AuthUtil";
// import { modalAddMovement } from "../movement/MovementActions"

class Movement extends Component {

  onDeleteClick = () => {
    const { movement } = this.props;
    this.props.deleteMovement(movement.id);
  };
  onEditClick = (movement) => {
    if (!this.props.isModalAddMovement){
      this.props.getStock(movement.stocked_product).then(() => {
        const { stock } = this.props.stocksReduc;
        if (stock.nf) {
          this.props.history.push(`/manage/nfs/edit/${stock.nf}?start_at=${movement.reference_date}&end_at=${movement.reference_date}`);
        }else{
          this.props.history.push(`/manage/stocks/edit?stock=FIX`);
        }
      }).catch((error) => {
        console.log(error)
      })
    }
    // else
      // this.props.modalAddMovement(movement)
  }

  render() {
    const { movement } = this.props;
    return (      
      <div className="btn-group">
        <Authorized>
          <Button className="btn btn-primary" onClick={() => this.onEditClick(movement)}>
            <PencilFill color="royalblue" />
          </Button>
        </Authorized>
        {/* <Button variant="danger" className="btn btn-primary" onClick={this.onDeleteClick}>
          <TrashFill color="red" />
        </Button> */}
      </div>      
    );
  }
}

Movement.propTypes = {
  movement: PropTypes.object.isRequired,
  getStock: PropTypes.func.isRequired,
  stocksReduc: PropTypes.object.isRequired
  // modalAddMovement: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  isModalAddMovement: state.movementsReduc.isModalAddMovement,
  stocksReduc: state.stocksReduc
});

export default connect(mapStateToProps, { deleteMovement, updateMovement, getStock
  // modalAddMovement 
})(
  withRouter(Movement)
);