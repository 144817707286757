
import React, { Component } from "react";
import { Button } from 'primereact/button';


class FloatLinks extends Component {
  render() {
    return (
      <>
      <div className="float">
        <Button icon="pi pi-whatsapp" size="large" onClick={e => window.open('https://wa.me/5527997159606', '_blank')} rounded severity="success" aria-label="Whatsapp" />
      </div>
      </>
    );
  }
}

export default FloatLinks;
