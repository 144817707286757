import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import AddProduct from "../components/commecialproduct/AddCommercialProduct";
import ProductList from "../components/commecialproduct/CommercialProductList"

class CommercialProduct extends Component {
    constructor() {
        super();
        this.state = {
            listing: true,
        };
    }

    render() {
        const { listing } = this.state;
        return (
            <div>
                <Container>
                    <ProductList />
                   
                        <Link  className="btn btn-primary position-sticky fixed-button" to="/manage/commercialproducts/edit">
                            Adicionar Produto Composto
                        </Link>
                    
                </Container>
            </div>
        );
    }
}

class CreateCommercialProduct extends Component {
    constructor() {
        super();
    }

    render() {

        return (
            <div>
                <Container>
                    <AddProduct />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const ListCP = connect(mapStateToProps, {
})(withRouter(CommercialProduct))

const CreateCP = connect(mapStateToProps, {
})(withRouter(CreateCommercialProduct))

export { ListCP, CreateCP }