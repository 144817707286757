import { GET_NFS, GET_NF, ADD_NF, DELETE_NF, UPDATE_NF} from "./NfTypes";

const initialState = {
  nfs: [],
  nf:{}
};

export const nfsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NFS:
      return {
        ...state,
        nfs: action.payload
      };
    case GET_NF:
      const nfUrl = action.payload.url || ''
      return {
        ...state,
        nf: { ...action.payload, url: nfUrl }
      };
    case ADD_NF:
      return {
        ...state,
        nfs: [...state.nfs, action.payload]
      };
    case DELETE_NF:
      return {
        ...state,
        nfs: state.nfs.filter((item, index) => item.id !== action.payload)
      };
    case UPDATE_NF:
      const updatedNfs = state.nfs.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        nfs: updatedNfs
      };
    default:
      return state;
  }
};