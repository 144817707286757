import React, { Component, CSSProperties } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";

const override: CSSProperties = {  
    // margin: "auto",
    // overflow: "show",
    // display: "block",
    position: "fixed",  
    zIndex:"1000", 
    top: "50%", 
    left: "50%", 
    opacity:1, 
    transform: "translate(-50%, -50%)"
  };

class Loader extends Component{

    constructor(props, context) {
        super(props)
        this.state = {
            loading: true,
            color: "#36d7b7",
            cssOverride: override,
            speedMultiplier: 1
        }
    }
    render(){
        const style = {
            width: '100%',
            height: '100%',      
            position: 'absolute', //fixed
            top: 0,
            left: 0,
            opacity: 0.5,
            background:'grey',
            'z-index':'999'
          };
        const { loading } = this.props;
        return (
            <>
                {loading && 
                // <div style={style}>
                <div>
                <RingLoader
                    color={this.state.color}
                    loading={this.state.loading}
                    cssOverride={this.state.cssOverride}
                    size={100}
                    margin={2}
                    speedMultiplier={1}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                </div>}
            </>
        )
    }

}

Loader.propTypes = {
    
};
const mapStateToProps = state => ({});

export default connect(mapStateToProps, { })(
  withRouter(Loader)
);