import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IMaskInput } from "react-imask";
import { withRouter, Link } from "react-router-dom";
import { cnpjMask, cnpjValidation, onlyNumber} from "../../utils/mask"
import { DateTime } from 'luxon';
import { addSupplier, updateSupplier, getSupplier } from "./SupplierActions";
import Loader from "components/utils/Loader"
import ProductList from "components/product/ProductList"
import ProductSupplierList from "components/product/ProductSupplierList";
import AddProductModal from "components/product/AddProductModal"
import { modalAddProduct, getProducts } from "../product/ProductActions"
import { getUfs } from "components/ufs/UfsActions"
import { getCitiesByUf } from "components/cities/CitiesActions"
import { putBlankProperties, blobToBase64, convertB64ToBlob, fixBlankProperties } from "../../utils/Utils";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    InputGroup,
    Row,
    Col,
    Modal,
    Table,
    Accordion
} from "react-bootstrap";

// https://www.codingame.com/playgrounds/9396/redux-form-validation-tutorial

class AddSupplier extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            co: {
                corporate_name: "",
                fantasy_name: "",
                cnpj:"",
                // cnpj_customer:"",
                address:"",
                city:"",
                state: "",
                neighborhood:"",
                main_phone:"",
                secondary_phone:"",                
                enabled:true
            },
            validated: false,
            loading: false
        }
        this.props.getUfs()
        this.props.suppliersReduc.supplier = this.state.co
        this.onChange = this.onChange.bind(this)
    }

    onChangeCnpj = e => {
        e.target.value = cnpjMask(e.target.value)
        this.onChange(e)
        let cnpj = e.target.value
        if (cnpjValidation(cnpj)) {
            var numcnpj = cnpj.replace(/\D+/g, '')
            // this.props.getSuspectByCNPJ(numcnpj)
        }
    };

    setValidated = (v) => {
        this.setState({ validated: v });
    }

    handleSubmit = (event) => {
        const form = document.getElementById("AddSupplier")
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setValidated(true);
        if (form.checkValidity() !== false) {

            this.onSaveClick();            
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.suppliersReduc.supplier.id !== prevState.co.id) {
            const { supplier } = nextProps.suppliersReduc;
            nextProps.getCitiesByUf(supplier.state)
            return {
                co: supplier,
                validated: prevState.validated
            };
        }
        else
            return null
    }
    componentDidMount() {
        const { id } = this.props.match.params;
        if (id != null) {
            this.props.getSupplier(id);
            this.props.getProducts(id)
        }
        else {
            this.props.suppliersReduc.supplier = this.state.co
        }
    }

     onChangeCheckbox = e => {        
        var co = { ...this.state.co }        
        co[e.target.name] = e.target.value === 'true' ? false : true
        this.setState({ co });
    }

    onChange = e => {
        var co = { ...this.state.co }
        co[e.target.name] = e.target.value        
        this.setState({ co });
    };

    onChangeUf = e => {
        this.onChange(e)
        this.setState({loading:true})
        this.props.getCitiesByUf(e.target.value).then(() => {
            this.setState({loading:false})
            var co = { ...this.state.co }
            co['city'] = ""
            this.setState({ co });
        })
    }

    onOpenModalProdutoClick = () => {
        const supplier =  this.state.co.id
        const product = {}
        this.props.modalAddProduct(product, supplier)
    };

    onSaveClick = (event) => {
        let supplier = {...this.state.co};
        supplier.cnpj = onlyNumber(supplier.cnpj)
        // supplier.cnpj_customer = onlyNumber(supplier.cnpj_customer)

        supplier.cep = onlyNumber(supplier.cep)
        supplier.number = onlyNumber(supplier.number)
        supplier.main_phone = onlyNumber(supplier.main_phone)
        supplier.secondary_phone = onlyNumber(supplier.secondary_phone)

        supplier = fixBlankProperties(supplier)

        if (!this.state.co.id){
            this.setState({loading:true})
            this.props.addSupplier(supplier).then(() => {
                this.setState({loading:false})
                this.props.history.push('/manage/suppliers');
                }
            )
            .catch(error => {
                this.setState({loading:false})
              }
            )
        }
        else{
            this.setState({loading:true})
            this.props.updateSupplier(this.state.co.id, supplier).then(() => {
                this.setState({loading:false})
                this.props.history.push('/manage/suppliers');
                }
            ).catch(error => {
                this.setState({loading:false})
              }
            )
        }
    };

    render() {
        const { ufs } = this.props.ufsReduc

        let uf_options = ufs.map(c => {
            return <option key={c.id} value={c.id}>{c.abreviation }</option>
        })

        const { cities } = this.props.citiesReduc

        let city_options = cities.map(c => {
            return <option key={c.id} value={c.id}>{c.city }</option>
        })
        return (
            <>
            <Loader loading={this.state.loading} />
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card>
                            <Card.Header>
                                <Card.Title as="h4" className="text-center text-secondary font-weight-bold ">Cadastro de Fornecedor</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form id="AddSupplier" noValidate validated={this.state.validated}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="corporate_name">
                                            <Form.Label className="font-weight-bold">Nome</Form.Label>
                                            <Form.Control required type="text" name="corporate_name" placeholder="Razão Social" value={this.state.co.corporate_name}
                                                onChange={this.onChange} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="fantasy_name">
                                            <Form.Label className="font-weight-bold">Nome fantasia</Form.Label>
                                            <Form.Control required type="text" name="fantasy_name" placeholder="Nome Fantasia" value={this.state.co.fantasy_name}
                                                onChange={this.onChange} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="cnpj">
                                            <Form.Label className="font-weight-bold">CNPJ</Form.Label>
                                            <Form.Control required type="text" name="cnpj" placeholder="CNPJ" value={this.state.co.cnpj}
                                               onChange={this.onChangeCnpj} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="address">
                                            <Form.Label className="font-weight-bold">Endereço</Form.Label>
                                            <Form.Control required type="text" name="address" placeholder="Endereço" value={this.state.co.address}
                                                onChange={this.onChange} maxLength={500} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="number">
                                            <Form.Label className="font-weight-bold">Número</Form.Label>
                                            <Form.Control type="text" name="number" placeholder="Número" value={this.state.co.number}
                                                onChange={this.onChange} maxLength={5}  />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="cep">
                                            <Form.Label className="font-weight-bold">CEP</Form.Label>
                                            <Form.Control required type="text" name="cep" placeholder="CEP" value={this.state.co.cep}
                                               onChange={this.onChange} maxLength={10} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3" controlId="neighborhood">
                                            <Form.Label className="font-weight-bold">Bairro</Form.Label>
                                            <Form.Control required type="text" name="neighborhood" placeholder="Bairro" value={this.state.co.neighborhood}
                                                onChange={this.onChange} maxLength={50} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="state">
                                            <Form.Label className="font-weight-bold">Estado</Form.Label>
                                            <Form.Control required as="select" name="state" placeholder="Estado" value={this.state.co.state}
                                               onChange={this.onChangeUf} >
                                                <option value="">--- SELECIONE ---</option>
                                                {uf_options}
                                            </Form.Control>
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="city">
                                            <Form.Label className="font-weight-bold">Cidade</Form.Label>
                                            <Form.Control required as="select" name="city" placeholder="Cidade" value={this.state.co.city}
                                                onChange={this.onChange} >
                                                <option value="">--- SELECIONE ---</option>
                                                {city_options}
                                            </Form.Control>
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="4" controlId="main_phone">
                                            <Form.Label className="font-weight-bold">Telefone principal</Form.Label>
                                            <Form.Control required type="text" name="main_phone" placeholder="(00) 0 0000-0000" value={this.state.co.main_phone}
                                                onChange={this.onChange}   maxLength={16} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="secondary_phone">
                                            <Form.Label className="font-weight-bold">Telefone opcional</Form.Label>
                                            <Form.Control type="text" name="secondary_phone" placeholder="(00) 0 0000-0000" value={this.state.co.secondary_phone}
                                                onChange={this.onChange}  maxLength={16} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    
                                    {/* <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="cnpj_customer">
                                            <Form.Label className="font-weight-bold">CNPJ Gestora</Form.Label>
                                            <Form.Control required type="text" name="cnpj_customer" placeholder="CNPJ" value={this.state.co.cnpj_customer}
                                                onChange={this.onChangeCnpj} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row> */}

                                    <Row className="mb-3">
                                        
                                        <Form.Group as={Col} md="4"> 
                                            <Form.Label className="font-weight-bold" >Ativo</Form.Label>
                                            <Form.Check id="enabled" name="enabled"
                                              className="mb-1 pl-0">
                                                <Form.Check.Label>
                                                <Form.Check.Input id="enabled" name="enabled" onChange={this.onChangeCheckbox}
                                                    checked = {this.state.co.enabled}
                                                    value = {this.state.co.enabled}
                                                    type="checkbox"
                                                ></Form.Check.Input>
                                                <span className="form-check-sign"></span>
                                                </Form.Check.Label>
                                            </Form.Check>
                                        </Form.Group>
                                    </Row>

                                </Form>
                            </Card.Body>
                        </Card>

                        {this.state.co.id && 
                        // <Card>
                        //     <Card.Title>
                        //         <ProductList supplier={this.state.co.id} showFilter= {false} showModal={true}/>                    
                        //     </Card.Title>
                        //     <Card.Body>
                        //         <Button variant="primary" onClick={this.onOpenModalProdutoClick}>
                        //         Novo Produto
                        //         </Button>
                        //     </Card.Body>
                        // </Card>
                        
                        <Card>
                            <Card.Title>
                                <ProductSupplierList supplier={this.state.co.id}/>                    
                            </Card.Title>
                            <Card.Body>
                                <Button variant="primary" onClick={this.onOpenModalProdutoClick}>
                                Novo Produto
                                </Button>
                            </Card.Body>
                        </Card>}

                        <Button className="btn btn btn-primary active btn-floating position-sticky" style={{ bottom: '20px', right: '20px'}} type="button" onClick={this.handleSubmit} >
                            Salvar
                        </Button>{' '}
                        <Link to="/manage/suppliers/" className="btn btn btn-danger active btn-floating position-sticky" style={{ bottom: '20px', right: '20px'}}>
                            Cancelar
                        </Link>
                    </Col>
                </Row>
                <AddProductModal supplier={this.state.co}/>
            </Container>
            </>
        )
    }
}

AddSupplier.propTypes = {
    addSupplier: PropTypes.func.isRequired,
    updateSupplier: PropTypes.func.isRequired,
    getSupplier: PropTypes.func.isRequired,
    suppliersReduc: PropTypes.object.isRequired,
    modalAddProduct: PropTypes.func.isRequired,
    getProducts: PropTypes.func.isRequired,
    getUfs: PropTypes.func.isRequired,
    getCitiesByUf: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    suppliersReduc: state.suppliersReduc,
    ufsReduc: state.ufsReduc,
    citiesReduc: state.citiesReduc
});

export default connect(mapStateToProps, { addSupplier, updateSupplier, getSupplier,modalAddProduct,getProducts, getUfs, getCitiesByUf })(withRouter(AddSupplier));