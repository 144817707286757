import axios from "axios";
//import { decodeBlobErrorMessage, toastOnSave } from "../../utils/Utils";
import { GET_REPORTS } from "./ReportTypes";

export const getReports = ( allProduct, product, size) => dispatch => {

  let url = "/api/v1/reports/";
  const params = [];


  if (allProduct) {
    params.push(`allProduct=${allProduct}`)
  }

  if (product) {
    params.push(`product=${product}`)
  }

  if (size) {
    params.push(`size=${size}`)
  }

  if (params.length > 0) {
    url += "?" + params.join("&");
  }

  return axios({
    url: url, // Replace with the actual URL of your PDF
    method: 'GET',
    responseType: 'blob', // Set responseType to 'blob'
  })
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });

      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank')

      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      //decodeBlobErrorMessage(error);
    });
};