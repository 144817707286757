import { toastOnError, toastOnSave } from "../../utils/Utils";
import axios from "axios";

export const uploadCollectBase = (file, hash, obj, onUploadProgress, route, type) => dispatch => {
    let formData = new FormData();
    formData.append("file_path", file);
    obj.hash = hash
    for (const i in obj){    
      formData.append(i, obj[i]);
    }
    return axios
      .post(route, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      })
      .then(response => {
        dispatch({
          type: type,
          payload: response.data
        });
        toastOnSave("Carregamento do arquivo realizado com sucesso")
      })
      .catch(error => {
        toastOnError(error);
        throw error
      });
  }