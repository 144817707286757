import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCommercialProducts } from "./CommercialProductActions";
import CommercialProduct from "./CommercialProduct"
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';
import {  formatDate } from "../../utils/Data";
import { header } from "../../utils/Datagrid";
import { FilterMatchMode } from 'primereact/api';
import Loader from "components/utils/Loader"
import { cnpjMask, cnpjValidation, onlyNumber, densityMask} from "../../utils/mask"

import {
  Card,
  Row,
  Col,
  Form,
} from "react-bootstrap";

class CommercialProductList extends Component {
  constructor(props) {
    super(props);
    this.dt = React.createRef();
    this.state = {
      filters: {
        ncm_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        commercial_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        density: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        enabled_virtual: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      },
      loading: false,
      options:{
        enabled:"1"
      },
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
      this.props.getCommercialProducts(this.state.options).then(() => {
    })
    .catch((error) => {})
    .finally(() => {
      this.setState({ loading: false })
    });
  }

  onChange = e => {
    var options = { ...this.state.options }
    options[e.target.name] = e.target.value
    this.setState({ loading: true, options })
    this.props.getCommercialProducts(options).then(() => {
      this.setState({loading: false})
    })
  };

  cols = [       
    { field: 'ncm_name', header: 'NCM Principal' },
    { field: 'commercial_name', header: 'Nome Comercial' },
    { field: 'density', header: 'Densidade' },
    { field: 'enabled_virtual', header: 'Ativo' }
  ];

  exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));
  
  dateBodyTemplate = (rowData) => {
    return formatDate(rowData.created_at);
  };

  alocBodyTemplate = (rowData) => {
    return <CommercialProduct key={rowData.id} commercialproduct={rowData} />
  }

  render() {
    const { commercialproducts } = this.props.commercialproductsReduc;
    
    let items = commercialproducts.map(item => {
        item.density = densityMask(item.density);
        item.enabled_virtual = item.enabled ? "Sim" : "Não"
      return item
    });
    
    return (
      <>
      <Loader loading={this.state.loading} />
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Cadastro de Produtos (Substâncias / Resíduos) Compostos</Card.Title>
              <p className="card-category">
                Substâncias e Resíduos Compostos
              </p>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <div className="card">
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable ref={this.dt} value={items} header={(<div>
                  <Row className="mb-3"></Row>
                  <Row>
                    <Col md="10">
                      <Row >
                        <Form.Group as={Col} md="5" controlId="search">
                          <Form.Label className="font-weight-bold" style={{ color: 'black', fontSize: '16px' }}>Situação</Form.Label>
                          <Form.Control
                            as="select" required name="enabled" value={this.state.options.enabled}
                            onChange={this.onChange} >
                            <option value='1'>Ativo</option>
                            <option value='0'>Inativo</option>
                          </Form.Control >
                          <Form.Control.Feedback>Ok</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Campo obrigatório.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    </Col>
                    <Col>
                      {header(commercialproducts, "Produtos compostos", this.dt, this.exportColumns)}
                    </Col>
                  </Row>
                </div>
                )}
                  tableStyle={{ minWidth: '50rem' }}
                  paginator rows={5}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      rowsPerPageOptions={[5, 10, 25, 50]}
                  filterDisplay="row"
                  filters={this.state.filters}
                  emptyMessage="Não há produtos cadastradas"
                  
                  >
                  {this.cols.map((col, index) => (
                      <Column key={index} filter sortable field={col.field} header={col.header} />
                  ))}
                  <Column body={this.alocBodyTemplate}></Column>
                </DataTable>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </>
    );
  }
}

CommercialProductList.propTypes = {
  getCommercialProducts: PropTypes.func.isRequired,
  commercialproductsReduc: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  commercialproductsReduc: state.commercialproductsReduc
});

export default connect(mapStateToProps, {
  getCommercialProducts
})(withRouter(CommercialProductList));