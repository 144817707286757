import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Button, Row, Col, Form, Card, Figure} from "react-bootstrap";
import { getUserPersons } from "components/userPerson/UserPersonsActions.js";
import { login, recoveryPassword} from "./LoginActions.js";
import { useTranslation, Trans, withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import requireAuth from "utils/RequireAuth.js";
import { setAxiosAuthToken, toastOnError, toastOnSave } from "../../utils/Utils";
import Loader from "components/utils/Loader"
import { getProfileByLoggedUser } from "../persons/PersonsActions";
import FloatLinks from "components/utils/FloatLinks.js";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      us: {
            username: "",
            password: ""
          },
          loading: false,
          validated: false
    };
  }
  onChange = e => {
    var {us} = this.state
    us[e.target.name] = e.target.value       
    this.setState({ us });
  };

  fetchUserProfile = () => {
    this.props.getProfileByLoggedUser(this.props.auth.user)
      .then(() => {
        this.setState({loading:false})
        this.props.history.push('/manage/operation');
      })
  }

  onLoginClick = () => {
    this.setState({loading:true})
    const userData = {
      username: this.state.us.username,
      password: this.state.us.password
    };
    this.props.login(userData, "/manage/operation").then(() => {
        this.fetchUserProfile()
      }
    )
    .catch(error => {
        this.setState({loading:false})
      }
    )
  };

  onResetClick = () => {
    this.setState({loading:true})
    this.props.recoveryPassword(this.state.us.username, "/login").then(() => {
        this.setState({loading:false})
        this.props.history.push('/login');
        }
    )
    .catch(error => {
        this.setState({loading:false})
      }
    )
  }

  setValidated = (v) => {
    this.setState({ validated: v });
  }

  handleSubmitReset = (event) => {
    const form = document.getElementById("loginForm")    
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setValidated(true);    
    if (form.checkValidity() !== false){
      this.onResetClick();
    }    
  };


  handleSubmit = (event) => {
    const form = document.getElementById("loginForm")    
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setValidated(true);    
    if (form.checkValidity() !== false){
      this.onLoginClick();
    }    
  };

  render() {
    const { t, i18n } = this.props;
    const query = new URLSearchParams(this.props.location.search);
    const reset = query.get("reset");
    return (
      <>
      <Loader loading={this.state.loading} />
      <Row>
        <Col md='4'></Col>
        <Col md='4'>
          <Card className="" >
            {/* <Card.Img variant="top" src={require("assets/img/new_logo.png")}/> */}
            <Card.Body>
              <Form id="loginForm" noValidate validated={this.state.validated}>
               
              {reset != 1 && <>
              <Card.Text>
                  <label>E-mail:</label>
                  <Form.Control
                  required
                  type="text"
                  name="username"
                  placeholder="E-mail"
                  value={this.state.us.username}
                  onChange={this.onChange}
                />
              </Card.Text>
              <Card.Text>
                  <label>Senha:</label>
                <Form.Control
                  required
                  type="password"
                  name="password"
                  placeholder="Senha"
                  value={this.state.password}
                  onChange={this.onChange}
                />
                        
              </Card.Text>

              <div className = "d-flex justify-content-center"> 
              <Button data-testid="btn-login" color="primary" onClick={this.handleSubmit}>
              Entrar
              </Button>
              </div>
              {/* <label>Não Possui cadastro? <Card.Link href= "/signup">Novo cadastro</Card.Link></label>  */}
              <label>Esqueceu a senha? <Card.Link href= "/login?reset=1">Recuperar senha</Card.Link></label> 

              </>}

              {reset == 1 && <>
              <Card.Text>
                  <label>E-mail:</label>
                  <Form.Control
                  required
                  type="text"
                  name="username"
                  placeholder="E-mail"
                  value={this.state.us.username}
                  onChange={this.onChange}
                />
              </Card.Text>

              <div className = "d-flex justify-content-center"> 
              <Button data-testid="btn-login" color="primary" onClick={this.handleSubmitReset}>
               Recuperar senha
              </Button>
              </div>
              {/* <label>Não Possui cadastro? <Card.Link href= "/signup">Novo cadastro</Card.Link></label>  */}
              <label>Acessar o sistema? <Card.Link href= "/login">Fazer login</Card.Link></label> 

              </>}
              <hr/>
              <Row>
                <Col></Col>
                <Col>
                  <Figure>
                  <Figure.Image              
                    src={require("assets/img/log4.jpeg")}/>
                  </Figure>
                </Col>
                <Col></Col>              
              </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md='4'></Col>
      </Row>
      <FloatLinks />
      </>
    );
  }
}

// connect action and store and component
Login.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  recoveryPassword: PropTypes.func.isRequired,
  userPersonsReduc: PropTypes.object.isRequired,
  getUserPersons: PropTypes.func.isRequired,
  getProfileByLoggedUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  userPersonsReduc: state.userPersonsReduc
});

export default connect(mapStateToProps, {
  login, recoveryPassword, getUserPersons, getProfileByLoggedUser
})(withRouter(withTranslation()(Login)));