import { GET_PERSONS, GET_PERSON, ADD_PERSON, DELETE_PERSON, UPDATE_PERSON} from "./PersonsTypes";

const initialState = {
  persons: [],
  person:{}
};

export const personsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERSONS:
      return {
        ...state,
        persons: action.payload
      };
    case GET_PERSON:
      return {
        ...state,
        person: action.payload
      };
    case ADD_PERSON:
      return {
        ...state,
        persons: [...state.persons, action.payload]
      };
    case DELETE_PERSON:
      return {
        ...state,
        persons: state.persons.filter((item, index) => item.id !== action.payload)
      };
    case UPDATE_PERSON:
      const updatedPersons = state.persons.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        persons: updatedPersons
      };
    default:
      return state;
  }
};