import { GET_UF , GET_UFS } from "./UfsTypes";

const initialState = {
  ufs: [],
  uf:{}
};

export const ufsReducer = (state = initialState, action) => { 
  switch (action.type) {
    case GET_UFS:
      return {
        ...state,
        ufs: action.payload
      };
    case GET_UF :
      return {
        ...state,
        uf: action.payload
      };
    default:
      return state;
  }
};