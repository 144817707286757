import { GET_NCMSYNONYMS, GET_NCMSYNONYM, ADD_NCMSYNONYM, DELETE_NCMSYNONYM, UPDATE_NCMSYNONYM} from "./NCMSynonymTypes";

const initialState = {
  ncmsynonyms: [],
  product:{}
};

export const ncmsynonymsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NCMSYNONYMS:
      return {
        ...state,
        ncmsynonyms: action.payload
      };
    case GET_NCMSYNONYM:
      return {
        ...state,
        product: action.payload
      };
    case ADD_NCMSYNONYM:
      return {
        ...state,
        ncmsynonyms: [...state.ncmsynonyms, action.payload]
      };
    case DELETE_NCMSYNONYM:
      return {
        ...state,
        ncmsynonyms: state.ncmsynonyms.filter((item, index) => item.id !== action.payload)
      };
    case UPDATE_NCMSYNONYM:
      const updatedNCMSynonyms = state.ncmsynonyms.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        ncmsynonyms: updatedNCMSynonyms
      };
    default:
      return state;
  }
};