import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { getSuspectsPhasesByOperation } from "./SuspectsActions";
import { DateTime } from 'luxon';
import { cpfMask, cnpjMask } from "../../utils/mask";
import { Bullseye, PencilFill, TrashFill, PersonBadgeFill, Check2Circle, GeoAltFill, BinocularsFill } from 'react-bootstrap-icons';
import { getProductSuppliers, modalAddProduct, deleteProductSupplier} from "./ProductActions";
import {
    Badge,
    Card,
    Button,
    Navbar,
    Nav,
    Table,
    Container,
    Modal,
    Row,
    Col,
} from "react-bootstrap";
import { Image } from 'primereact/image';
import { convertB64ToBlob } from "../../utils/Utils";
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';
import { formatDate } from "../../utils/Data";
import { header } from "../../utils/Datagrid";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import Loader from "components/utils/Loader"

class ProductSupplierList extends Component {
    constructor(props) {
        super(props);
        this.dt = React.createRef();
        this.props.getProductSuppliers({supplier:this.props.suppliersReduc.supplier.id})
        this.state = {
            filters: {
                name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                ncm_general_code: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                ncm_general_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                ncm_synonym_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            },
            loading: false,
            isDeleting: false
        }
    }

    cols = [
        { field: 'ncm_general_code', header: 'Código NCM' },
        { field: 'ncm_general_name', header: 'Nome NCM' },
        { field: 'product.commercial_name', header: 'Nome Comercial' },
        { field: 'product.density', header: 'Densidade' },
        { field: 'product.concentration', header: 'Concentração' },
    ];

    exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));

    onOpenModalProductClick = (product_supplier) => {
        this.props.modalAddProduct(null, null, product_supplier);
      }

      onRemoveClick = () => {
        this.setState({
          isDeleting: true
        })
      }
      onCancelClick = () => {
        this.setState({
          isDeleting: false,
        })
      }
      onDeleteClick = (idProduct) => {
        this.setState({ loading: true })
        this.props.deleteProductSupplier(idProduct).then(() => {
            this.setState({ loading: false, isDeleting: false })
            this.props.getProductSuppliers({supplier:this.props.suppliersReduc.supplier.id})
          }
          )
      };
    buttonsBarBody = (product_supplier) => {
        return (
            <div className="btn-group">
                <Button title="Editar produto" variant="primary" onClick={() => this.onOpenModalProductClick(product_supplier)} >
                    <PencilFill color="royalblue" />
                </Button>
                <Button title="Excluir produto" variant="danger" className="btn btn-primary" onClick={this.onRemoveClick}>
                    <TrashFill color="red" />
                </Button>
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={this.state.isDeleting} >
          <Modal.Body>
            <h6>Deseja realmente excluir o produto {product_supplier.ncm_general_name}?</h6>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-danger active" onClick={() => this.onCancelClick()}>Não</Button>
            <Button onClick={() => this.onDeleteClick(product_supplier.id)}>Sim</Button>
          </Modal.Footer>
        </Modal>
            </div>
        )
    }

    render() {
        const { product_suppliers } = this.props.productsReduc;
        let items2 = product_suppliers.filter(pd => pd.supplier == this.props.supplier).map(product_supplier => {
            product_supplier.product.density = product_supplier.product.density.replace(".", ",")
            return product_supplier

        });

        return (
            <Row>
                <Col md="12">
                    <Card className="strpied-tabled-with-hover">
                        <Card.Header>
                            <Card.Title as="h4">Produtos</Card.Title>
                        </Card.Header>
                        <Card.Body className="table-full-width table-responsive px-0">

                            <div className="card">
                                <DataTable
                                    ref={this.dt} value={items2}
                                    header={header(product_suppliers, "Produtos", this.dt, this.exportColumns)}
                                    paginator rows={2}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    rowsPerPageOptions={[2, 4, 6, 8]}
                                    emptyMessage="Não há produtos">
                                    {this.cols.map((col, index) => (
                                        <Column key={index} filter sortable field={col.field} header={col.header} />
                                    ))}
                                    <Column body={this.buttonsBarBody}></Column>

                                </DataTable>
                            </div>
                        </Card.Body>

                    </Card>
                </Col>
            </Row>
        );
    }
}

ProductSupplierList.propTypes = {

    getProductSuppliers: PropTypes.func.isRequired,
    productsReduc: PropTypes.object.isRequired,
    modalAddProduct: PropTypes.object.isRequired,
    suppliersReduc: PropTypes.object.isRequired,
    deleteProductSupplier: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    productsReduc: state.productsReduc,
    suppliersReduc: state.suppliersReduc
});

export default connect(mapStateToProps, {
    cpfMask, cnpjMask, getProductSuppliers, modalAddProduct, deleteProductSupplier
})(withRouter(ProductSupplierList));