import { GET_USERPERSONS, GET_USERPERSON, ADD_USERPERSON, DELETE_USERPERSON, UPDATE_USERPERSON} from "./UserPersonsTypes";

const initialState = {
  userPersons: [],
  userUserPerson:{}
};

export const userPersonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERPERSONS:
      return {
        ...state,
        userPersons: action.payload
      };
    case GET_USERPERSON:
      return {
        ...state,
        userUserPerson: action.payload
      };
    case ADD_USERPERSON:
      return {
        ...state,
        userPersons: [...state.userPersons, action.payload]
      };
    case DELETE_USERPERSON:
      return {
        ...state,
        userPersons: state.userPersons.filter((item, index) => item.id !== action.payload)
      };
    case UPDATE_USERPERSON:
      const updatedUserPersons = state.userPersons.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        userPersons: updatedUserPersons
      };
    default:
      return state;
  }
};