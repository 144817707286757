import React, { Component, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { modalAddProduct } from "./ProductActions"
import AddProduct from "./AddProduct";
import PropTypes from "prop-types";
// react-bootstrap components
import {
    Button,
    Modal
} from "react-bootstrap";
import { connect } from "react-redux";

class AddProductModal extends Component {

    constructor(props, context) {
        super(props, context);
    }


    onCancelClick() {
       this.props.modalAddProduct(null)
    }

    render() {
       return (
            <Modal size="lg" show={this.props.isModalAddProduct}>
                <Modal.Body>
                    <AddProduct supplier={this.props.supplier}/>
                </Modal.Body>
                <Modal.Footer>                    
                    {/* <Button className="btn btn-danger active" onClick={() => this.onCancelClick()}>Sair</Button> */}
                </Modal.Footer>
            </Modal>
       )
    }
}

AddProductModal.propTypes = {
    productsReduc: PropTypes.object.isRequired,
    modalAddProduct: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    productsReduc: state.productsReduc,
    isModalAddProduct: state.productsReduc.isModalAddProduct
});

export default connect(mapStateToProps, {modalAddProduct})(withRouter(AddProductModal));