import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IMaskInput } from "react-imask";
import { withRouter, Link } from "react-router-dom";
import { cnpjMask, cnpjValidation, onlyNumber, densityMask } from "../../utils/mask"
import { DateTime } from 'luxon';
import { addProduct, updateProduct, getProduct, modalAddProduct } from "./ProductActions";
import Loader from "components/utils/Loader"
import { getNCMSynonymsByNCMGeneral } from "../ncmsynonym/NCMSynonymActions"
import { putBlankProperties, blobToBase64, convertB64ToBlob, fixBlankProperties } from "../../utils/Utils";
import { InputNumber } from 'primereact/inputnumber';
import { getSuppliers } from "components/supplier/SupplierActions";
import { getProducts, addProductSupplier, getProductSuppliers, updateProductSupplier } from "./ProductActions";
import { getCompanies } from "../company/CompanyActions";
import ControlDensity from "components/utils/ControlDensity"
import ControlConcentration from "components/utils/ControlConcentration"
import AutoCompleteNCM from "components/utils/AutoCompleteNCM";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    InputGroup,
    Row,
    Col,
    Modal,
    Table,
    Accordion
} from "react-bootstrap";

// https://www.codingame.com/playgrounds/9396/redux-form-validation-tutorial

class AddProduct extends Component {
    constructor(props, context) {
        super(props, context);
        this.props.getSuppliers()
        this.props.getCompanies()
        this.props.getProductSuppliers()
        this.state = {
            el: {
                ncm_general: "",
                ncm_synonym: "",
                commercial_name: "",
                concentration: "",
                density: "",
                supplier: "",
                customer: "",
                enabled: true
            },
            validated: false,
            loading: false
        }
        this.props.productsReduc.product = this.state.el
        this.onChange = this.onChange.bind(this)
        this.onChangeNCMGeneral = this.onChangeNCMGeneral.bind(this)
    }

    setValidated = (v) => {
        this.setState({ validated: v });
    }

    handleSubmit = (event) => {
        const form = document.getElementById("AddProduct")
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setValidated(true);
        if (form.checkValidity() !== false) {

            this.onSaveClick();
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.productsReduc.product && nextProps.productsReduc.product.id !== prevState.el.id) {
            var { product } = nextProps.productsReduc;
            product = putBlankProperties(product)
            product.concentration = product.concentration.toString()
            nextProps.getNCMSynonymsByNCMGeneral(product.ncm_general)
            return {
                el: product,
                validated: prevState.validated
            };
        }
        else
            return null
    }
    componentDidMount() {
        if (!this.props.isModalAddProduct) {
            const { id } = this.props.match.params;
            if (id != null) {
                this.setState({ loading: true })
                this.props.getProduct(id).then(() => {
                    this.setState({ loading: false })
                    console.log(this.props.productsReduc.product)
                    this.props.productsReduc.product.density = this.props.productsReduc.product.density.replace('.', ',')
                });
            }
            else {
                this.props.productsReduc.product = this.state.el
            }
        }
        else {
            this.props.productsReduc.product = this.props.productsReduc.product_supplier.product
        }
    }

    onChangeCheckbox = e => {
        var el = { ...this.state.el }
        el[e.target.name] = e.target.value === 'true' ? false : true
        this.setState({ el });
    }

    onChangeNCMGeneral = e => {
        this.onChange(e)
        this.setState({ loading: true })
        this.props.getNCMSynonymsByNCMGeneral(e.target.value).then(() => {
            this.setState({ loading: false })
            var el = { ...this.state.el }
            console.log(el)
            el['ncm_synonym'] = ""
            this.setState({ el });
        })
    }

    onChange = e => {
        var el = { ...this.state.el }
        el[e.target.name] = e.target.value
        this.setState({ el });
    };

    onChangeConcentration = e => {
        e.target.value = onlyNumber(e.target.value)
        this.onChange(e)
    }

    onChangeDensity = e => {
        // e.target.value = densityMask(e.target.value)
        this.onChange(e)
    };

    onSaveClick = (event) => {
        let element = { ...this.state.el };
        element.density = element.density.replace(/,/g, '.')
        if (this.state.el.id)
            element.id = this.state.el.id

        var isAdding = (this.props.isModalAddProduct && !this.props.productsReduc.product_supplier.id)
            || (!this.props.isModalAddProduct && !element.id)

        element = fixBlankProperties(element)

        if (isAdding) {
            element.id = null
            var product_supplier = {
                id: null,
                product: element,
                supplier_id: this.props?.supplier?.id ?? null
            }
            this.setState({ loading: true })
            this.props.addProductSupplier(product_supplier).then(() => {
                // this.props.modalAddProduct(this.state.el, product_supplier.supplier);
                this.props.getProductSuppliers({ supplier: this.props.suppliersReduc.supplier.id })

                this.checkAfterSave()
                this.setState({ loading: false })
            }).catch(err => { this.setState({ loading: false }) })
        }
        else {
            var product_supplier = {
                product: element,
                id: this.props.productsReduc.product_supplier.id ?? null,
                supplier_id: this.props.productsReduc.product_supplier.supplier ?? null
            }
            this.setState({ loading: true })
            this.props.updateProductSupplier(product_supplier).then(() => {
                this.props.getProductSuppliers({ supplier: this.props.suppliersReduc.supplier.id })
                this.setState({ loading: false })
                // this.props.modalAddProduct(null, null, this.props.productsReduc.product_supplier);
                this.checkAfterSave()
            }).catch(err => { this.setState({ loading: false }) })
        }
    }

    checkAfterSave = () => {
        this.setState({ loading: false })
        if (this.props.isModalAddProduct) {
            this.props.modalAddProduct(this.state.el)
        }
        else
            this.props.history.push('/manage/products');
    }

    onCancel = () => {
        this.checkAfterSave()
    }

    render() {
        const { ncmgenerals } = this.props.ncmgeneralsReduc
        const { suppliers } = this.props.suppliersReduc
        const { companies } = this.props.companiesReduc
        console.log(this.state.el.ncm_general)

        let companies_options = companies.map(com => {
            return <option key={com.id} value={com.id}>{`${com.corporate_name} | ${cnpjMask(com.cnpj)}`}</option>
        })

        let suppliers_options = suppliers.map(sup => {
            return <option key={sup.id} value={sup.id}>{`${sup.corporate_name} | ${cnpjMask(sup.cnpj)}`}</option>
        })

        let ncmgenerals_options = ncmgenerals.map(c => {
            return <option key={c.id} value={c.id}>{c.name + ' ' + c.code}</option>
        })

        const { ncmsynonyms } = this.props.ncmsynonymsReduc

        let ncmsynonyms_options = ncmsynonyms.map(c => {
            return <option key={c.id} value={c.id}>{c.name}</option>
        })
        return (
            <>
                <Loader loading={this.state.loading} />
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <Card>
                                <Card.Header>
                                    <Card.Title as="h4" className="text-center text-secondary font-weight-bold ">Cadastro de Produto</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form id="AddProduct" noValidate validated={this.state.validated}>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4">
                                                {/* <Form.Label className="font-weight-bold" >NCM</Form.Label>
                                                <Form.Control
                                                    as="select" required aria-label="NCM" name="ncm_general" onChange={this.onChangeNCMGeneral} value={this.state.el.ncm_general}>
                                                    <option value="">--- SELECIONE ---</option>
                                                    {ncmgenerals_options}
                                                </Form.Control>
                                                <Form.Control.Feedback>Ok</Form.Control.Feedback> */}
                                                <AutoCompleteNCM 
                                                    name="ncm_general"
                                     
                                                    required={true} 
                                                    onChange={(e) => this.onChangeNCMGeneral(e)} 
                                                    value={this.state.el.ncm_general} />
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId="commercial_name">
                                                <Form.Label className="font-weight-bold">Nome Comercial</Form.Label>
                                                <Form.Control type='text' name="commercial_name" placeholder="Nome"
                                                    onChange={this.onChange}
                                                    value={this.state.el.commercial_name}
                                                    maxLength={250}
                                                />
                                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Campo obrigatório.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">

                                            <Form.Group as={Col} md="4" controlId="density">
                                                <ControlDensity onChangeDensity={this.onChangeDensity} value={this.state.el.density} />
                                            </Form.Group>

                                            <Form.Group as={Col} md="4" controlId="concentration">
                                                <ControlConcentration value={this.state.el.concentration} min={1} max={100}
                                                    onChangeConcentration={e => this.onChangeConcentration(e)} />
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                            <Button className="btn btn btn-primary active btn-floating position-sticky" style={{ bottom: '20px', right: '20px' }} type="button" onClick={this.handleSubmit} >
                                Salvar
                            </Button>{' '}
                            <Button onClick={this.onCancel} className="btn btn btn-danger active btn-floating position-sticky" style={{ bottom: '20px', right: '20px' }}>
                                Cancelar
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

AddProduct.propTypes = {
    addProduct: PropTypes.func.isRequired,
    updateProduct: PropTypes.func.isRequired,
    getProduct: PropTypes.func.isRequired,
    productsReduc: PropTypes.object.isRequired,
    ncmgeneralsReduc: PropTypes.object.isRequired,
    ncmsynonymsReduc: PropTypes.object.isRequired,
    modalAddProduct: PropTypes.func.isRequired,
    getNCMSynonymsByNCMGeneral: PropTypes.func.isRequired,
    suppliersReduc: PropTypes.object.isRequired,
    getSuppliers: PropTypes.func.isRequired,
    getProducts: PropTypes.func.isRequired,
    companiesReduc: PropTypes.object.isRequired,
    getCompanies: PropTypes.func.isRequired,
    addProductSupplier: PropTypes.func.isRequired,
    updateProductSupplier: PropTypes.func.isRequired,
    getProductSuppliers: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    productsReduc: state.productsReduc,
    ncmgeneralsReduc: state.ncmgeneralsReduc,
    ncmsynonymsReduc: state.ncmsynonymsReduc,
    isModalAddProduct: state.productsReduc.isModalAddProduct,
    suppliersReduc: state.suppliersReduc,
    companiesReduc: state.companiesReduc
});

export default connect(mapStateToProps, { addProduct, getProductSuppliers, getProducts, updateProduct, getProduct, updateProductSupplier, getNCMSynonymsByNCMGeneral, modalAddProduct, addProductSupplier, getSuppliers, getCompanies })(withRouter(AddProduct));