import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_NFS, GET_NF, ADD_NF, DELETE_NF, UPDATE_NF, UPLOAD_NF} from "./NfTypes";
import { uploadCollectBase } from "../servicebase/BaseActions";

export const getNfs = (options={number:null}) => dispatch => {

  var url = "/api/v1/nfs/"
  var complement = []
  if (options.number){
    complement.push(`number=${options.number}`)
  }

  if (options.enabled){
    complement.push(`enabled=${options.enabled}`)
  }

  if (options.start_at){
    complement.push(`start_at=${options.start_at}`)
  }

  if (options.end_at){
    complement.push(`end_at=${options.end_at}`)
  }

  if (options.number_contains){
    complement.push(`number_contains=${options.number_contains}`)
  }

  if (options.order_by){
    complement.push(`order_by=${options.order_by}`)
  }

  if (options.confirmed){
    complement.push(`confirmed=${options.confirmed}`)
  }

  if (complement.length > 0){
    url += "?" + complement.join("&")
  }

  return axios
    .get(url)
    .then(response => {      
      dispatch({
        type: GET_NFS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const addNf = nf => dispatch => {
  return axios
    .post("/api/v1/nfs/", nf)
    .then(response => {
      dispatch({
        type: ADD_NF,
        payload: response.data
      });
      toastOnSave("Inclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const deleteNf = id => dispatch => {
  axios
    .delete(`/api/v1/nfs/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_NF,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const uploadCollect = (file, hash, call, onUploadProgress) => dispatch => { 
  return uploadCollectBase(file, hash, call, onUploadProgress, "/api/v1/nfs/", UPLOAD_NF)(dispatch)
}

export const getNf = id => dispatch => {
  return axios
    .get(`/api/v1/nfs/${id}/`)
    .then(response => {
      dispatch({
        type: GET_NF,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const updateNf = (id, nf) => dispatch => {
  return axios
    .patch(`/api/v1/nfs/${id}/`, nf)
    .then(response => {
      dispatch({
        type: UPDATE_NF,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const getEnabledNf = (enabled) => dispatch => {
  return axios
  .get(`/api/v1/nfs/?enabled=${enabled}`)
  .then(response => {      
    dispatch({
      type: GET_NFS,
      payload: response.data
    });
  })
  .catch(error => {
    toastOnError(error);
  });
}
