import { GET_COMMERCIAL_PRODUCTS, GET_COMMERCIAL_PRODUCT, ADD_COMMERCIAL_PRODUCT, DELETE_COMMERCIAL_PRODUCT, UPDATE_COMMERCIAL_PRODUCT, MODAL_ADD_COMMERCIAL_PRODUCT} from "./CommercialProductTypes";

const initialState = {
  commercialproducts: [],
  commercialproduct:{},
  isModalAddCommercialProduct:false
};

export const commercialproductsReducer = (state = initialState, action) => {
  switch (action.type) {    
    case GET_COMMERCIAL_PRODUCTS:
      return {
        ...state,
        commercialproducts: action.payload
      };
    case GET_COMMERCIAL_PRODUCT:
      return {
        ...state,
        commercialproduct: action.payload
      };
    case ADD_COMMERCIAL_PRODUCT:
      return {
        ...state,
        commercialproducts: [...state.commercialproducts, action.payload]
      };
    case DELETE_COMMERCIAL_PRODUCT:
      return {
        ...state,
        commercialproducts: state.commercialproducts.filter((item, index) => item.id !== action.payload)
      };
    case UPDATE_COMMERCIAL_PRODUCT:
      const updatedCommercialProducts = state.commercialproducts.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        commercialproducts: updatedCommercialProducts
      };
    case MODAL_ADD_COMMERCIAL_PRODUCT:
      if (action.payload)
        state.commercialproduct = action.payload
      state.isModalAddCommercialProduct = !state.isModalAddCommercialProduct
      return {
        ...state
      }
    default:
      return state;
  }
};