import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DateTime } from 'luxon';
import { Check2Circle } from 'react-bootstrap-icons';
import { toastOnSave } from "../../utils/Utils";
import { getCalls, searchCall } from "../calls/CallsAction"
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';
import { formatDate } from "../../utils/Data";
import { header } from "../../utils/Datagrid";
import { FileEarmarkArrowDownFill } from 'react-bootstrap-icons';
import { Search } from 'react-bootstrap-icons';
import { toastOnError } from "../../utils/Utils";
import Loader from "components/utils/Loader"
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import Call from "./Call";
import { FilterMatchMode, FilterOperator } from 'primereact/api';

class CallsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doc: {
        search: ""
      },
      filters: {
        company_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        company_cnpj: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        created_at: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      },
      loading: true,
    }

  }
  company_name
  cols = [
    { field: 'company_name', header: 'Nome Gestora', filter: true },
    { field: 'company_cnpj', header: 'CNPJ Gestora', filter: true },
    { field: 'created_at', header: 'Data de registro', filter: true },
    { field: 'description', header: 'Descrição', filter: true },
    { field: 'file_path', header: 'Arquivo' }
  ];

  exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));

  docBodyTemplate = (rowData) => {
    return <Call key={rowData.id} call={rowData} />
  }

  componentDidMount() {
    this.setState({ loading: true })
    this.props.getCalls().then(() => {
      
    })
    .catch((error) => {})
    .finally(() => {
      this.setState({ loading: false })
    });
  }

  formatarData(data) {
    if (data.length == 10) {
      return data
    }
    const dataObj = new Date(data);
    const dia = String(dataObj.getDate()).padStart(2, '0');
    const mes = String(dataObj.getMonth() + 1).padStart(2, '0');
    const ano = dataObj.getFullYear();
    return `${dia}/${mes}/${ano}`;
  }
  handleSubmit = (e) => {
    var search_value = this.state.doc.search
    if (search_value == '') {
      this.props.getCalls()
      toastOnError("Insira uma expressão para pesquisa")
    }
    if (search_value) {
      this.setState({ loading: true })
      this.props.searchCall(search_value).then(() => {
        this.setState({ loading: false })
      }
      )
    };
  }
  onChange = e => {
    var doc = { ...this.state.doc }
    doc[e.target.name] = e.target.value
    this.setState({ doc });
  };

  render() {
    const { calls } = this.props.callsReduc;

    let listDoc = calls.map(call => {
      var obj = {...call}
      obj['created_at'] = this.formatarData(call.created_at)
      obj['file_path'] = <a target="_blank" download={true} rel="noopener noreferrer" href={call.file_path}><FileEarmarkArrowDownFill color="gray" size={40} /></a>
      
      return obj

    })
    if ((this.props.phase_number || this.props.phase_number == 0) && this.props.enabledOperation) {

      var listDocFilter = listDoc.filter(call => {
        return parseInt(call.phase_number) === parseInt(this.props.phase_number)
         && parseInt(call.operation) === parseInt(this.props.operation);
      })

    } 


    return (
      <>
        <Loader loading={this.state.loading} />

        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h2">Chamados</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <div className="card">
                  <Tooltip target=".export-buttons>button" position="bottom" />
                  <DataTable
                    ref={this.dt} value={this.props.operation ? listDocFilter:listDoc }
                    paginator rows={5}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    filterDisplay="row"
                    filters={this.state.filters}
                    emptyMessage="Não há Chamados"
                    tableStyle={{ minWidth: '50rem' }}>
                    {this.cols.map((col, index) => (
                      <Column key={index} filter={col.filter} sortable field={col.field} header={col.header} />
                    ))}
                    {(!this.props.phase_number && !this.props.operation) &&
                      <Column body={this.docBodyTemplate}></Column>
                    }

                  </DataTable>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

CallsList.propTypes = {
  callsReduc: PropTypes.object.isRequired,
  getCalls: PropTypes.func.isRequired,
  searchCall: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  callsReduc: state.callsReduc,
});

export default connect(mapStateToProps, {
  getCalls, searchCall

})(withRouter(CallsList));