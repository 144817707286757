import React, { Component } from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import Login from "./login/Login";
import Signup from "./signup/Signup";
import FloatLinks from "components/utils/FloatLinks";

class Home extends Component {
  render() {
    return (
      <>
      <Login/>
      {/* <Container>
        <React.StrictMode>          
          <Login/>    
        </React.StrictMode>
      </Container> */}
      </>
    );
  }
}

export default Home;