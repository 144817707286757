import axios from "axios";
import { toast } from "react-toastify";

export const setAxiosAuthToken = token => {
  if (typeof token !== "undefined" && token) {
    // Apply for every request
    axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const toastOnError = error => {
  console.log(error)
  if (error.response) {
    // known error
    toast.error(formatMessage(error.response.data));
  } else if (error.message) {
    toast.error(JSON.stringify(error.message));
  } else {
    toast.error(JSON.stringify(error));
  }
};

export const toastOnSave = message => {
  toast.success(message || 'Operação realizada com sucesso')
}

export const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

const formatMessage = values => {
  var messages = []
  console.log(values)
  if (values['business_error']){
    console.log(values['business_error'])
    messages.push(values['business_error'])
    messages = messages.join('\n')
  }else{
    try{
      for (var v in values){
        messages.push(...values[v])
      }
      messages = messages.join('\n')
    }catch{
      messages = JSON.stringify(values) || "Falha Interna"
    }
  }
  return messages;
}

function addZero(x, n) {
  while (x.toString().length < n) {
    x = "0" + x;
  }
  return x;
}

export const toISOStringWithTimezone = date => {
  date = new Date(date)
  const tzOffset = -date.getTimezoneOffset();
  const diff = tzOffset >= 0 ? '+' : '-';
  const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    '.' + pad(addZero(date.getMilliseconds(), 6)) +
    diff + pad(tzOffset / 60) +
    ':' + pad(tzOffset % 60);
};

export const fixBlankProperties = obj => {
  var props = Object.getOwnPropertyNames(obj)
  for (let index = 0; index < props.length; index++) {
    const element = obj[props[index]];
    if (element === '')
      obj[props[index]] = null
  } 
  return obj;
}

export const putBlankProperties = obj => {
  var props = Object.getOwnPropertyNames(obj)
  for (let index = 0; index < props.length; index++) {
    const element = obj[props[index]];
    if (!element)
      obj[props[index]] = ''
  } 
  return obj;
}

export const blobToBase64 = blob => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const convertB64ToBlob = b64file =>  {
  var parts = b64file.split(';base64,')
  const contenttype = parts[0].replace('data:','')
  const b64 = parts[1]
  return b64toBlob(b64, contenttype)
}

export const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}