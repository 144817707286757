import { GET_MOVEMENTS, GET_MOVEMENT, ADD_MOVEMENT, DELETE_MOVEMENT, UPDATE_MOVEMENT, MODAL_ADD_MOVEMENT} from "./MovementTypes";

const initialState = {
  movements: [],
  movement:{},
  isModalAddMovement:false
};

export const movementsReducer = (state = initialState, action) => {
  switch (action.type) {    
    case GET_MOVEMENTS:
      return {
        ...state,
        movements: action.payload
      };
    case GET_MOVEMENT:
      return {
        ...state,
        movement: action.payload
      };
    case ADD_MOVEMENT:
      return {
        ...state,
        movements: [...state.movements, action.payload]
      };
    case DELETE_MOVEMENT:
      return {
        ...state,
        movements: state.movements.filter((item, index) => item.id !== action.payload)
      };
    case UPDATE_MOVEMENT:
      const updatedMovements = state.movements.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        movements: updatedMovements
      };
    case MODAL_ADD_MOVEMENT:
      if (action.payload)
        state.movement = action.payload
      state.isModalAddMovement = !state.isModalAddMovement
      return {
        ...state
      }
    default:
      return state;
  }
};