import axios from "axios";
import { toast } from "react-toastify";
import { toastOnError, toastOnSave } from "../../utils/Utils";

import {
  CREATE_USER_ERROR,
  CREATE_USER_SUBMITTED,
  CREATE_USER_SUCCESS
} from "./SignupTypes";

export const signupNewUser = userData => dispatch => {
  dispatch({ type: CREATE_USER_SUBMITTED }); // set submitted state

  // delete userData.passwordToCheck
  // delete userData.emailToCheck

  return axios
    .post("/api/v1/users/", userData, {
      headers: {
        'Authorization': `` 
      }
    })
    .then(response => {
      toast.success(
        "A conta do usuário " +
          userData.username +
          " foi criada com sucesso. Por favor, faça o login."
      );
      dispatch(
        { type: CREATE_USER_SUCCESS,
          payload: response.data
        });
      })      
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const activateUser = userData => dispatch => {
  return axios
    .post("/api/v1/users/activation/", userData,{
      headers: {
        'Authorization': `` 
      }
    })
    .then(response => {
      toast.success("Conta ativada.");
      // dispatch({ type: CREATE_USER_SUCCESS });
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};