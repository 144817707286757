import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { deleteProduct, updateProduct } from "./ProductActions";
import { Button } from "react-bootstrap";
import { PencilFill, TrashFill, Check2Circle} from 'react-bootstrap-icons';
import { modalAddProduct } from "../product/ProductActions"
import ConfirmationModal from "components/utils/ConfirmationModal";
import { Authorized } from "utils/AuthUtil";

class Product extends Component {
  onDeleteClick = () => {
    const { product } = this.props;
    this.props.deleteProduct(product.id);
  };
  onEditClick = (product) => {
    this.props.modalAddProduct(product)
  }
  render() {
    const { product } = this.props;
    return (      
      <div className="btn-group">
      { this.props.modal &&
        <Button className="btn btn-primary" onClick={() => this.onEditClick(product)}>
          <PencilFill color="royalblue" />
        </Button>}
       { !this.props.modal &&<Link to={`/manage/products/edit/${product.id}`} role="button" className="btn btn-primary">          
          <PencilFill color="royalblue" />
        </Link>}
        <Authorized>
          <ConfirmationModal title="Deseja inativar este produto" function={this.onDeleteClick} />
        </Authorized>
      </div>      
    );
  }
}

Product.propTypes = {
  product: PropTypes.object.isRequired,
  modalAddProduct: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  isModalAddProduct: state.productsReduc.isModalAddProduct
});

export default connect(mapStateToProps, { deleteProduct, updateProduct, modalAddProduct })(
  withRouter(Product)
);