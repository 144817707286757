import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Container,
  Button,
  Card,
  Row,
  Col,
  Form,
  FormControl
} from "react-bootstrap";

import { signupNewUser,activateUser } from "./SignupActions"

import { Message } from 'primereact/message';
import Loader from "components/utils/Loader"
import { toast } from "react-toastify";


class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        first_name:"",
        last_name:"",
        username: "",
        password: "",
        email: "",
        emailToCheck:"",
        re_password: ""
      },
      validated:false,
      loading: false
    };
  }
  onChange = e => {
    const {user} = { ...this.state}    
    user[e.target.name] = e.target.value
    this.setState({ user });
  };

  onChangeUsername = e => {
    const {user} = {...this.state.person}
    user[e.target.name] = e.target.value
    this.setState({user})
  }

  setValidated = (v) => {
    this.setState({ validated: v });
  }

  handleSubmit = (event) => {
    const form = document.getElementById("addUser")    
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setValidated(true);    
    if (form.checkValidity() !== false){
      this.onSignupClick();
    }    
  };

  // update function to call the action
  onSignupClick = () => {
    let userData = this.state.user    
    // userData.username = userData.email.split('@')[0]
    userData.username = userData.email    
    if (userData.emailToCheck !== userData.email){
      toast.error("os e-mails não coincidem")
    }
    else{
      this.setState({loading:true})
      this.props.signupNewUser(userData)
      .then(() => {
          this.setState({loading:false})
          this.props.history.push('/login');
          }
      )
      .catch(error => {
          this.setState({loading:false})
        }
      )
    }    
  };

  sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  render() {
    const query = new URLSearchParams(this.props.location.search);
    const uid = query.get("uid");
    const token = query.get("token")
    const active = query.get("active")
    if (active === "1"){
      return (
      <Container>
        <Row>
          <Message severity="success" text="A sua conta foi ativada com sucesso. Por favor, faça o login." />
        </Row>
        <br/>
        <Row>
        <label><Card.Link href= "/login">Fazer login</Card.Link></label> 
        </Row>
        
      </Container>)
    }
    else{ 
      if (active === "0"){
        return (
          <Container>
            <Row>
            <Message severity="error" text="Erro na ativação da sua conta ou ela já está ativa. Por favor, faça o login" />
            </Row>
            <br/>
            <Row>
            <label><Card.Link href= "/login">Fazer login</Card.Link></label> 
            </Row>
          </Container>)
      }
    }

    if (token && uid){
      this.props.activateUser({uid:uid, token:token}).then(() => {
        this.props.history.push('/signup?active=1')
      }
      ).catch(error => {
        this.props.history.push('/signup?active=0')
      })
    }else
    return (
      <>
      <Loader loading={this.state.loading} />
      <Container>
        <Row>
          <Col >
            <Card>
              <Card.Header>
                  <Card.Title as="h4" className="text-center text-secondary font-weight-bold ">Registrar novo usuário</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form id="addUser" noValidate validated={this.state.validated}>
                  {/* <Row className="mb-3">

                    <Form.Group as={Col} xs={6} controlId="firstname">
                      <Form.Label className="font-weight-bold">Nome</Form.Label>
                      <Form.Control
                        required
                        // isInvalid={this.props.createUser.firstnameError}
                        type="text"
                        name="first_name" 
                        placeholder="Primeiro Nome"
                        value={this.state.user.first_name}
                        onChange={this.onChange}
                      />
                      <Form.Control.Feedback>Ok</Form.Control.Feedback>
                      <FormControl.Feedback type="invalid">
                        {this.props.createUser.firstnameError}
                        Campo obrigatório
                      </FormControl.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} xs={6} controlId="lastname">
                      <Form.Label className="font-weight-bold">Sobrenome</Form.Label>
                      <Form.Control
                        required
                        // isInvalid={this.props.createUser.lastnameError}
                        type="text"
                        name="last_name" 
                        placeholder="Sobrenome"
                        value={this.state.user.last_name}
                        onChange={this.onChange}
                      />
                      <Form.Control.Feedback>Ok</Form.Control.Feedback>
                      <FormControl.Feedback type="invalid">
                        {this.props.createUser.lastnameError}
                        Campo obrigatório
                      </FormControl.Feedback>
                    </Form.Group>
                </Row> */}
                <Row>
                  <Form.Group as={Col} xs={6} controlId="usernameId">
                    <Form.Label className="font-weight-bold">E-mail</Form.Label>
                    <Form.Control
                      required
                      // isInvalid={this.props.createUser.usernameError}
                      type="text"
                      name="email"
                      placeholder="Seu E-mail"
                      value={this.state.user.email}
                      onChange={this.onChange}
                    />
                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                    <FormControl.Feedback type="invalid">
                      {/* {this.props.createUser.usernameError} */}
                      Campo obrigatório
                    </FormControl.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} xs={6} controlId="usernameIdRepeated">
                    <Form.Label className="font-weight-bold">Confirme o E-mail</Form.Label>
                    <Form.Control
                      required
                      // isInvalid={this.props.createUser.usernameError}
                      type="text"
                      name="emailToCheck" 
                      placeholder="Seu E-mail"
                      value={this.state.user.emailToCheck}
                      onChange={this.onChange}
                    />
                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                    <FormControl.Feedback type="invalid">
                      {/* {this.props.createUser.usernameError} */}
                      Campo obrigatório
                    </FormControl.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group  as={Col} xs={6} controlId="passwordId">
                    <Form.Label className="font-weight-bold">Senha</Form.Label>
                    <Form.Control
                      // isInvalid={this.props.createUser.passwordError}
                      required
                      type="password"
                      name="password"
                      placeholder="Senha"
                      value={this.state.user.password}
                      onChange={this.onChange}
                    />
                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {/* {this.props.createUser.passwordError} */}
                      Campo obrigatório
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group  as={Col} xs={6} controlId="passwordIdToCheck">
                    <Form.Label className="font-weight-bold">Confirme a Senha</Form.Label>
                    <Form.Control
                      required
                      // isInvalid={this.props.createUser.passwordError}
                      type="password"
                      name="re_password"
                      placeholder="Senha"
                      value={this.state.user.re_password}
                      onChange={this.onChange}
                    />
                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {/* {this.props.createUser.passwordError} */}
                      Campo obrigatório
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </Form>
              <Button color="primary" onClick={this.handleSubmit}>
              Cadastrar
            </Button>
            <p className="mt-2">
              Já possui? <Link to="/login">Login</Link>
            </p>
            </Card.Body>
           </Card>
          </Col>
        </Row>
      </Container>
      </>
    );
  }
}

// connect action and reducer
// replace 
// export default Signup;
// with code below:

Signup.propTypes = {
  signupNewUser: PropTypes.func.isRequired,
  activateUser: PropTypes.func.isRequired,
  signupReduc: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  signupReduc: state.signupReduc
});

export default connect(mapStateToProps, {
  signupNewUser,activateUser
})(withRouter(Signup));


