import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { addFpFile, updateFpFile, getFpFile, modalAddFpFile } from "./FpFileActions";
import { getCommercialProducts, getCommercialProduct } from "components/commecialproduct/CommercialProductActions"
import { getProducts, getProduct } from "components/product/ProductActions";
import Loader from "components/utils/Loader"
import { getNCMGenerals } from "../ncmgeneral/NCMGeneralActions"
import { getNCMSynonymsByNCMGeneral } from "../ncmsynonym/NCMSynonymActions"
import { getCompanies } from "../company/CompanyActions"
import { putBlankProperties, blobToBase64, convertB64ToBlob, fixBlankProperties, toastOnError }
    from "../../utils/Utils"; import { getMonthOptions, getMovementTypeOptions, getMovementSubTypeOptions } from "utils/OptionsUtils"

// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    InputGroup,
    Row,
    Col,
    Modal,
    Table,
    Accordion
} from "react-bootstrap";
import FpFile from "./FpFile";

// https://www.codingame.com/playgrounds/9396/redux-form-validation-tutorial

class AddFpFile extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            el: {
                month: "",
                year: "",
                production: false,
                transformation: false,
                consumption: true,
                factory: false,
                transport: true,
                storing: true,
                national: true,
                international: true,
            },
            validated: false,
            loading: false
        }
        this.props.getNCMGenerals()
        // this.props.getCommercialProducts();       
        this.onChange = this.onChange.bind(this)
        this.onChangeNCMGeneral = this.onChangeNCMGeneral.bind(this)
    }

    setValidated = (v) => {
        this.setState({ validated: v });
    }
    getActivityFromCompany = () => {
        this.setState({ loading: true })
        this.props.getCompanies().then(() => {
            const { companies } = this.props.companiesReduc
            if (companies.length > 0) {
                const {
                    production,
                    transformation,
                    consumption,
                    factory,
                    transport,
                    storing,
                    national,
                    international
                } = companies[0];
                this.setState(prevState => ({
                    el: {
                        ...prevState.el,
                        production: production,
                        transformation: transformation,
                        consumption: consumption,
                        factory: factory,
                        transport: transport,
                        storing: storing,
                        national: national,
                        international: international
                    }
                }));


            }

        })
            .catch((error) => {
                toastOnError("Ocorreu um erro na operação")
            })
            .finally(() => {
                this.setState({ loading: false })
            });
    }




    handleSubmit = (event) => {
        const form = document.getElementById("AddFpFile")
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setValidated(true);
        if (form.checkValidity() !== false) {

            this.onSaveClick();
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        // if (nextProps.fpFilesReduc.fpFile.id !== prevState.el.id) {
        //     var { fpFile } = nextProps.fpFilesReduc;
        //     fpFile = putBlankProperties(fpFile)           

        //     if (fpFile.id){
        //         var st = AddFpFile.parser(fpFile)
        //         return {
        //             el:st.el,
        //             sps:st.sps,
        //             validated: prevState.validated
        //         };
        //     }
        //     else
        //         return null
        // }
        // else
        //     return null
        return null
    }

    static parser(commercialproduct) {
        var st = {}
        st.el = { ...commercialproduct }
        st.sps = []
        if (commercialproduct.substances)
            commercialproduct.substances.map((e, index) => st.sps.push({ pr: e }))
        delete st.el.substances
        return st
    }

    componentDidMount() {
        if (!this.props.isModalAddFpFile) {
            const { id } = this.props.match.params;
            if (id != null) {
                this.setState({ loading: true })

                this.props.getFpFile(id).then(() => {
                    var st = AddFpFile.parser(this.props.fpFilesReduc.fpFile)
                    this.setState({ el: st.el, sps: st.sps, loading: false })
                })
            }
            else {
                this.props.fpFilesReduc.fpFile = this.state.el
                this.getActivityFromCompany()
            }
        }
        else {
            var el = this.props.fpFilesReduc.fpFile
            this.setState({ el })
        }
    }

    onChangeCheckbox = e => {
        var el = { ...this.state.el }
        el[e.target.name] = e.target.value === 'true' ? false : true
        this.setState({ el });
    }

    onChange = e => {
        var el = { ...this.state.el }
        el[e.target.name] = e.target.value
        this.setState({ el });
    };

    onSaveClick = (event) => {
        if (!this.state.el.id) {
            this.setState({ loading: true })
            this.props.addFpFile(this.state.el).then(() => {
                this.checkAfterSave()
            }
            )
                .catch(error => {
                    this.setState({ loading: false })
                }
                )
        }
        else {
            this.setState({ loading: true })
            this.props.updateFpFile(this.state.el.id, this.state.el).then(() => {
                this.checkAfterSave()
            }
            ).catch(error => {
                this.setState({ loading: false })
            }
            )
        }
    };

    checkAfterSave = () => {
        this.setState({ loading: false })
        if (this.props.isModalAddFpFile)
            this.props.modalAddFpFile(null)
        else
            this.props.history.push('/manage/fpFiles');
    }

    onCancel = () => {
        this.checkAfterSave()
    }

    onChangeProduct = (e, index, type = 'pr', name = null, value = null) => {
        var sps = this.state.sps
        var spsIndexed = sps[index]
        var obj = { ...spsIndexed[type] }
        if (name) {
            obj[name] = value
        } else {
            obj[e.target.name] = e.target.value
        }
        sps[index][type] = obj
        this.setState({ sps })
    }

    onChangeNCMGeneral = (e, index) => {
        this.onChangeProduct(e, index)
        this.setState({ loading: true })
        this.props.getNCMSynonymsByNCMGeneral(e.target.value).then(() => {
            this.setState({ loading: false })

            const { ncmsynonyms } = this.props.ncmsynonymsReduc
            var ncmsynonyms_options = ncmsynonyms.map(c => {
                return <option key={c.id} value={c.id}>{c.name}</option>
            })
            this.onChangeProduct(e, index, 'pr', 'ncm_synonym', "")
            this.onChangeProduct(e, index, 'ncmsynonyms', 'options', ncmsynonyms_options)
        })
    }

    onRemoveSpFromReview = () => {
        var sps = this.state.sps
        sps.pop()
        this.setState({ sps })
    }

    addSpToReview = () => {
        var sps = this.state.sps
        sps.push({
            ncmsynonyms: { options: [] },
            pr: {
                ncm_general: "",
                concentration: "",
                enabled: true
            }
        })
        this.setState({ sps })
    }
    generateYears(currentYear) {
        var currentYear = new Date().getFullYear()
        return Array.from({ length: 5 }, (_, i) => currentYear - i);
    }


    render() {


        var years = this.generateYears()

        let years_options = years.map(year => (
            <option key={year} value={year}>
                {year}
            </option>
        ))

        return (
            <>
                <Loader loading={this.state.loading} />
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <Card>
                                <Card.Header>
                                    <Card.Title as="h4" className="text-center text-secondary font-weight-bold ">Gerar Arquivo para Remessa</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form id="AddFpFile" noValidate validated={this.state.validated}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4" controlId="month">
                                                <Form.Label required className="font-weight-bold">Mês</Form.Label>
                                                <Form.Control
                                                    as="select" required aria-label="month" name="month" onChange={e => this.onChange(e)} value={this.state.el.month}>
                                                    <option value="">--- SELECIONE ---</option>
                                                    {getMonthOptions(0)}
                                                </Form.Control>
                                                <Form.Control.Feedback >Ok</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Campo obrigatório.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            {/* <Form.Group as={Col} md="4" controlId="year">                                            
                                            <Form.Label required className="font-weight-bold">Ano</Form.Label>
                                            <Form.Control type='number' step="1" min="2022" max="2100"
                                                required aria-label="year" name="year" onChange={e => this.onChange(e)} value={this.state.el.year}>
                                            </Form.Control>
                                            <Form.Control.Feedback >Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Um ano válido é necessário.
                                            </Form.Control.Feedback>
                                        </Form.Group> */}

                                            <Form.Group as={Col} md="3" controlId="year">
                                                <Form.Label className="font-weight-bold">Ano</Form.Label>
                                                <Form.Control required as="select" name="year" placeholder="Ano" value={this.state.el.year}
                                                    onChange={this.onChange}>
                                                    <option value="">--- SELECIONE ---</option>
                                                    {years_options}
                                                </Form.Control>
                                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Campo obrigatório.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <h5>Atividades</h5>
                                                <hr />
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label className="font-weight-bold" >Produção</Form.Label>
                                                <Form.Check id="production" name="production"
                                                    className="mb-1 pl-0">
                                                    <Form.Check.Label>
                                                        <Form.Check.Input id="production" name="production" onChange={this.onChangeCheckbox}
                                                            checked={this.state.el.production}
                                                            value={this.state.el.production}
                                                            type="checkbox"
                                                        ></Form.Check.Input>
                                                        <span className="form-check-sign"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label className="font-weight-bold" >Transformação</Form.Label>
                                                <Form.Check id="transformation" name="transformation"
                                                    className="mb-1 pl-0">
                                                    <Form.Check.Label>
                                                        <Form.Check.Input id="transformation" name="transformation" onChange={this.onChangeCheckbox}
                                                            checked={this.state.el.transformation}
                                                            value={this.state.el.transformation}
                                                            type="checkbox"
                                                        ></Form.Check.Input>
                                                        <span className="form-check-sign"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label className="font-weight-bold" >Consumo</Form.Label>
                                                <Form.Check id="consumption" name="consumption"
                                                    className="mb-1 pl-0">
                                                    <Form.Check.Label>
                                                        <Form.Check.Input id="consumption" name="consumption" onChange={this.onChangeCheckbox}
                                                            checked={this.state.el.consumption}
                                                            value={this.state.el.consumption}
                                                            type="checkbox"
                                                        ></Form.Check.Input>
                                                        <span className="form-check-sign"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label className="font-weight-bold" >Fabricação</Form.Label>
                                                <Form.Check id="factory" name="factory"
                                                    className="mb-1 pl-0">
                                                    <Form.Check.Label>
                                                        <Form.Check.Input id="factory" name="factory" onChange={this.onChangeCheckbox}
                                                            checked={this.state.el.factory}
                                                            value={this.state.el.factory}
                                                            type="checkbox"
                                                        ></Form.Check.Input>
                                                        <span className="form-check-sign"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label className="font-weight-bold" >Transporte</Form.Label>
                                                <Form.Check id="transport" name="transport"
                                                    className="mb-1 pl-0">
                                                    <Form.Check.Label>
                                                        <Form.Check.Input id="transport" name="transport" onChange={this.onChangeCheckbox}
                                                            checked={this.state.el.transport}
                                                            value={this.state.el.transport}
                                                            type="checkbox"
                                                        ></Form.Check.Input>
                                                        <span className="form-check-sign"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label className="font-weight-bold" >Armazenagem</Form.Label>
                                                <Form.Check id="storing" name="storing"
                                                    className="mb-1 pl-0">
                                                    <Form.Check.Label>
                                                        <Form.Check.Input id="storing" name="storing" onChange={this.onChangeCheckbox}
                                                            checked={this.state.el.storing}
                                                            value={this.state.el.storing}
                                                            type="checkbox"
                                                        ></Form.Check.Input>
                                                        <span className="form-check-sign"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label className="font-weight-bold" >Nacional</Form.Label>
                                                <Form.Check id="national" name="national"
                                                    className="mb-1 pl-0">
                                                    <Form.Check.Label>
                                                        <Form.Check.Input id="national" name="national" onChange={this.onChangeCheckbox}
                                                            checked={this.state.el.national}
                                                            value={this.state.el.national}
                                                            type="checkbox"
                                                        ></Form.Check.Input>
                                                        <span className="form-check-sign"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label className="font-weight-bold" >Internacional</Form.Label>
                                                <Form.Check disabled={true} id="international" name="international"
                                                    className="mb-1 pl-0">
                                                    <Form.Check.Label>
                                                        <Form.Check.Input id="international" name="international" onChange={this.onChangeCheckbox}
                                                            checked={this.state.el.international}
                                                            value={this.state.el.international}
                                                            type="checkbox"
                                                        ></Form.Check.Input>
                                                        <span className="form-check-sign"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                            <Button className="btn btn btn-primary active btn-floating position-sticky" style={{ bottom: '20px', right: '20px' }} type="button" onClick={this.handleSubmit} >
                                Salvar
                            </Button>{' '}
                            <Button onClick={this.onCancel} className="btn btn-danger active btn-floating position-sticky" style={{ bottom: '20px', right: '20px' }}>
                                Cancelar
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

AddFpFile.propTypes = {
    addFpFile: PropTypes.func.isRequired,
    updateFpFile: PropTypes.func.isRequired,
    getCommercialProducts: PropTypes.func.isRequired,
    getCommercialProduct: PropTypes.func.isRequired,
    getProducts: PropTypes.func.isRequired,
    getProduct: PropTypes.func.isRequired,
    getFpFile: PropTypes.func.isRequired,
    fpFilesReduc: PropTypes.object.isRequired,
    ncmgeneralsReduc: PropTypes.object.isRequired,
    ncmsynonymsReduc: PropTypes.object.isRequired,
    getNCMGenerals: PropTypes.func.isRequired,
    modalAddFpFile: PropTypes.func.isRequired,
    getNCMSynonymsByNCMGeneral: PropTypes.func.isRequired,
    commercialproductsReduc: PropTypes.object.isRequired,
    productsReduc: PropTypes.object.isRequired,
    getCompanies: PropTypes.func.isRequired,
    companiesReduc: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    fpFilesReduc: state.fpFilesReduc,
    ncmgeneralsReduc: state.ncmgeneralsReduc,
    ncmsynonymsReduc: state.ncmsynonymsReduc,
    isModalAddFpFile: state.fpFilesReduc.isModalAddFpFile,
    commercialproductsReduc: state.commercialproductsReduc,
    productsReduc: state.productsReduc,
    companiesReduc: state.companiesReduc,
});

export default connect(mapStateToProps, {
    addFpFile, updateFpFile, getFpFile, getNCMGenerals,
    getNCMSynonymsByNCMGeneral, modalAddFpFile, getCommercialProducts, getCommercialProduct, getProducts, getProduct, getCompanies
})(withRouter(AddFpFile));