
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getReports } from 'components/reports/ReportActions';
import { IMaskInput } from "react-imask";
import { getProducts } from "../product/ProductActions";
import Loader from "components/utils/Loader"
import { onlyNumber } from "../../utils/mask";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  InputGroup,
  Row,
  Col,
  Modal,
  Table,
  Accordion
} from "react-bootstrap";

class ReportList extends Component {
  constructor(props) {
    super(props);
    this.props.getProducts({ is_message_to_user: false, enabled: true })
    this.state = {

      rp: {
        allProduct: "",
        product: "",
        size: ""
      },
      disabled: false,
      showOperatioPhase: false,
      loading: false,
      validated: false
    }

  }
  setValidated = (v) => {
    this.setState({ validated: v });
  }
  onChange = e => {
    var rp = { ...this.state.rp }
    rp[e.target.name] = e.target.value
    this.setState({ rp })

  };


  handleSubmit = (event) => {
    const form = document.getElementById("genReport")
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setValidated(true);
    if (form.checkValidity() !== false) {
      this.setState({ loading: true })
      this.props.getReports(
        this.state.rp.allProduct,
        this.state.rp.product).then(() => {
        this.props.onCloseModal()
          this.setState({ loading: false });
        })
    }
  };
  clearButton = (e) => {
    this.setState({
      rp: {
        allProduct: "",
        product: ""
      },
      disabled: false
    })

  }


  render() {
    const { products } = this.props.productsReduc;
    let products_options = products.map(c => {          
      var commercial_name = c.commercial_name ? "- " + c.commercial_name.toUpperCase() : ""  
      return <option key={c.id} value={c.id}>{ `${c.ncm_general_code} ${c.ncm_general_name} ${commercial_name} ` }</option>
  })
    

    return (
      <>
        <Loader loading={this.state.loading} />
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title as="h4" className="text-center text-secondary font-weight-bold ">Relatórios</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form id="genReport" noValidate validated={this.state.validated}>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="4" controlId="allProduct">
                        <Form.Label className="font-weight-bold">Gerar para todos os produtos</Form.Label>
                        <Form.Control
                          as="select" required aria-label="allProduct" name="allProduct" onChange={this.onChange} value={this.state.rp.allProduct}>
                          <option value="">--- SELECIONE ---</option>
                          <option value={"1"}>SIM</option>
                          <option value={"0"}>NÃO</option>
                        </Form.Control>
                        <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Campo obrigatório.
                        </Form.Control.Feedback>
                      </Form.Group>

                      {/* <Form.Group as={Col} md="4" controlId="size">
                        <Form.Label className="font-weight-bold">Tamanho do QRCode</Form.Label>
                        <Form.Control
                          as="select" required aria-label="size" name="size" onChange={this.onChange} value={this.state.rp.size}>
                          <option value="">--- SELECIONE ---</option>
                          <option value={"5"}>Pequeno</option>
                          <option value={"7"}>Médio</option>
                          <option value={"10"}>Grande</option>
                        </Form.Control>
                        <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Campo obrigatório.
                        </Form.Control.Feedback>
                      </Form.Group> */}
                    </Row>
                    {this.state.rp.allProduct == "0" && <Row className="mb-3">
                      <Form.Group as={Col} md="3" controlId="product">
                        <Form.Label className="font-weight-bold">Produto</Form.Label>
                        <Form.Control
                          as="select" required aria-label="Fase" name="product" value={this.state.rp.product} onChange={this.onChange} >
                          <option value="">--- SELECIONE ---</option>
                          {products_options}
                        </Form.Control>
                        <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Campo obrigatório.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>}




                    <Button className="btn btn-light active" type="button" onClick={this.handleSubmit} >
                      Gerar
                    </Button>{" "}
                    <Button icon="pi pi-times" type='button' onClick={this.clearButton} className="btn btn-danger active">
                      &nbsp;Limpar
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>

        </Container>
      </>
    );
  }
}

ReportList.propTypes = {
  reportsReduc: PropTypes.object.isRequired,
  productsReduc: PropTypes.object.isRequired,
  getReports: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  reportsReduc: state.reportsReduc,
  productsReduc: state.productsReduc

});

export default connect(mapStateToProps, {
  getReports, getProducts
})(withRouter(ReportList));