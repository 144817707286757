import { GET_FPFILES, GET_FPFILE, ADD_FPFILE, DELETE_FPFILE, UPDATE_FPFILE, MODAL_ADD_FPFILE} from "./FpFileTypes";

const initialState = {
  fpFiles: [],
  fpFile:{}
};

export const fpFilesReducer = (state = initialState, action) => {
  switch (action.type) {    
    case GET_FPFILES:
      return {
        ...state,
        fpFiles: action.payload
      };
    case GET_FPFILE:
      return {
        ...state,
        fpFile: action.payload
      };
    case ADD_FPFILE:
      return {
        ...state,
        fpFiles: [...state.fpFiles, action.payload]
      };
    case DELETE_FPFILE:
      return {
        ...state,
        fpFiles: state.fpFiles.filter((item, index) => item.id !== action.payload)
      };
    case UPDATE_FPFILE:
      const updatedFpFiles = state.fpFiles.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        fpFiles: updatedFpFiles
      };
    default:
      return state;
  }
};