import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IMaskInput } from "react-imask";
import { withRouter, Link } from "react-router-dom";
import { MaskInput } from "../../utils/mask"
import { addPerson, updatePerson, getPerson } from "./PersonsActions";
import {getCompanies} from "components/company/CompanyActions"
import Loader from "components/utils/Loader"
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    InputGroup,
    Row,
    Col,
    Modal,
    Table,
    Accordion
} from "react-bootstrap";

// https://www.codingame.com/playgrounds/9396/redux-form-validation-tutorial

class AddPerson extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            pe: {
                first_name: "",
                last_name: "",
                company:""
            },
            validated: false,
            loading: false
        }
        this.props.personsReduc.person = this.state.pe
        this.props.getCompanies()

    }
    setValidated = (v) => {
        this.setState({ validated: v });
    }

    handleSubmit = (event) => {
        const form = document.getElementById("addPerson")
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setValidated(true);
        if (form.checkValidity() !== false) {

            this.onSaveClick();
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.personsReduc.person.id !== prevState.pe.id) {
            const { person } = nextProps.personsReduc;
            return {
                pe: person,
                validated: prevState.validated
            };
        }
        else
            return null
    }
    componentDidMount() {
        const { id } = this.props.match.params;
        if (id != null) {
            this.props.getPerson(id);
        }
        else {
            this.props.personsReduc.person = this.state.pe
        }
    }

    onChange = e => {
        var pe = { ...this.state.pe }
        pe[e.target.name] = e.target.value        
        this.setState({ pe });
    };

    onSaveClick = (event) => {
        const person = this.state.pe;
        this.setState({loading:true})
        if (!this.state.pe.id)
            this.props.addPerson(person).then(() => {
                this.setState({loading:false})
                this.props.history.push('/manage/persons');
            }).catch((err => {
                this.setState({loading:false})
            })); 
        else
            this.props.updatePerson(this.state.pe.id, person).then(() => {
                this.setState({loading:false})
                this.props.history.push('/manage/persons');
            }).catch((err => {
                this.setState({loading:false})
            })); 
    };

    render() {
        const { companies } = this.props.companiesReduc
        let companies_options = companies.map(c => {
            return <option key={c.id} value={c.id}>{c.corporate_name} | {c.cnpj}</option>
        })

        return (
            <>
            <Loader loading={this.state.loading} />
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card>
                            <Card.Header>
                                <Card.Title as="h4" className="text-center text-secondary font-weight-bold ">Cadastro de Pessoas</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form id="addPerson" noValidate validated={this.state.validated}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="first_name">
                                            <Form.Label className="font-weight-bold">Nome</Form.Label>
                                            <Form.Control required type="text" name="first_name" placeholder="Primeiro nome" value={this.state.pe.first_name}
                                                onChange={this.onChange} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="last_name">
                                            <Form.Label className="font-weight-bold">Sobrenome</Form.Label>
                                            <Form.Control required type="text" name="last_name" placeholder="Primeiro nome" value={this.state.pe.last_name}
                                                onChange={this.onChange} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4">                                            
                                            <Form.Label className="font-weight-bold" >Empresa Gestora</Form.Label>
                                            <Form.Control
                                                as="select" aria-label="Empresa Gestoras" name="company" onChange={this.onChange} value={this.state.pe.company}>
                                                <option value="">--- SELECIONE ---</option>
                                                {companies_options}
                                            </Form.Control>
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                        </Form.Group>

                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Button className="btn btn btn-primary active btn-floating position-sticky" style={{ bottom: '20px', right: '20px'}} type="button" onClick={this.handleSubmit} >
                            Salvar
                        </Button>{' '}
                        <Link to="/manage/persons/" className="btn btn-danger active btn-floating position-sticky" style={{ bottom: '20px', right: '20px'}}>
                            Cancelar
                        </Link>
                    </Col>
                </Row>
            </Container>
            </>
        )
    }
}

AddPerson.propTypes = {
    addPerson: PropTypes.func.isRequired,
    updatePerson: PropTypes.func.isRequired,
    getPerson: PropTypes.func.isRequired,
    personsReduc: PropTypes.object.isRequired,
    companiesReduc: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    personsReduc: state.personsReduc,
    companiesReduc: state.companiesReduc

});

export default connect(mapStateToProps, { addPerson, updatePerson, getPerson,getCompanies })(withRouter(AddPerson));