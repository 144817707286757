import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import AddSupplier from "../components/supplier/AddSupplier";
import SupplierList from "../components/supplier/SupplierList"

class Supplier extends Component {
    constructor() {
        super();
        this.state = {
            listing: true,
        };
    }

    render() {
        const { listing } = this.state;
        return (
            <div>
                <Container>
                    <SupplierList />
                  
                        <Link  className="btn btn-primary position-sticky fixed-button" to="/manage/suppliers/edit">
                            Adicionar Fornecedor
                        </Link>
               
                </Container>
            </div>
        );
    }
}

class CreateSupplier extends Component {
    constructor() {
        super();
    }

    render() {

        return (
            <div>
                <Container>
                    <AddSupplier />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const ListSu = connect(mapStateToProps, {
})(withRouter(Supplier))

const CreateSu = connect(mapStateToProps, {
})(withRouter(CreateSupplier))

export { ListSu, CreateSu }