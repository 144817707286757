import { API_URL } from "environment/env"
import React from "react";
import ReactDOM from "react-dom/client";
import { Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Root from "./Root";
import AdminLayout from "layouts/Admin.js";
import Home from "./components/Home";
import Signup from "./components/signup/Signup";
import Login from "./components/login/Login";
import PasswordReset from "./components/login/PasswordReset";
import Dashboard from "./components/dashboard/Dashboard";
import { ToastContainer } from "react-toastify";
import axios from "axios";
axios.defaults.baseURL = API_URL;
import requireAuth from "./utils/RequireAuth";
import "react-toastify/dist/ReactToastify.css";
import './i18n';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <div>
    <Root>
      <ToastContainer hideProgressBar={true} newestOnTop={true} />
      <Switch>
        <Route path="/manage" component={requireAuth(AdminLayout)} />
        {/* <Route path="/manage" component={AdminLayout} /> */}
        {/* <Redirect from="/" to="/manage/dashboard" /> */}
        <Route path="/signup" component={Signup} />
        <Route path="/login" component={Login} />
        <Route path="/passwordreset" component={PasswordReset} />
        {/* <Route path="/dashboard" component={requireAuth(Dashboard)} /> */}
        <Route path="/dashboard" component={requireAuth(Dashboard)} />
        <Route exact path="/" component={Home} />
        <Route path="*">Ups</Route>
      </Switch>
    </Root>
  </div>
);
