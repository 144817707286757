

export const getMuOptions = (i) => {
    return [<option key={i++} value='l'>litro</option>, <option key={i++} value='kg'>kilograma</option>]
}

export const getMovementTypeOptions  = (i) => {
    return [
        <option key={i++} value='3'>Utilização p/ Consumo</option>, 
        <option key={i++} value='4'>Utilização p/ Produção</option>,
        <option key={i++} value='5'>Utilização p/ Transformação</option>
    ]
}

export const getMovementSubTypeOptions  = (i, type) => {
    const dic = {3: [
        <option key={i++} value='1'>Limpeza e Manutenção</option>, 
        <option key={i++} value='2'>Análises Laboratoriais</option>,
        <option default key={i++} value='3'>Outros</option>,
        <option key={i++} value='4'>Processo Produtivo</option>,
        <option key={i++} value='5'>Tratamento de Afluentes e Efluentes</option>
    ]}

    return dic[type] ?? null
}

export const getMonthOptions = (i) => {
    const dic = [
        <option key={i++} value='1'>Janeiro</option>,
        <option key={i++} value='2'>Fevereiro</option>,
        <option key={i++} value='3'>Março</option>,
        <option key={i++} value='4'>Abril</option>,
        <option key={i++} value='5'>Maio</option>,
        <option key={i++} value='6'>Junho</option>,
        <option key={i++} value='7'>Julho</option>,
        <option key={i++} value='8'>Agosto</option>,
        <option key={i++} value='9'>Setembro</option>,
        <option key={i++} value='10'>Outubro</option>,
        <option key={i++} value='11'>Novembro</option>,
        <option key={i++} value='12'>Dezembro</option>
    ]
    return dic
}


