import { DateTime } from 'luxon';

export const formatDate = (stringValue, localization = 'pt-BR') => {
    return new Date(stringValue).toLocaleDateString(localization, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
  };

  export const formatDateAndHours = (stringValue, localization = 'pt-BR') => {
    return new Date(stringValue).toLocaleDateString(localization, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour:'numeric',
        minute:'numeric',
        second:'numeric'
    });
  };

export const formaDateSimple = (stringValue) => {
    return new Date(stringValue).toLocaleDateString()
}

export const dateFromIso = (dateobj) => {
    return DateTime.fromISO(dateobj, {setZone: true}).toLocaleString()
}
