import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_CITY, GET_CITIES } from "./CitiesTypes";

export const getCities = () => dispatch => {
  axios
    .get("/api/v1/cities/")
    .then(response => {      
      dispatch({
        type: GET_CITIES,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getCity = id => dispatch => {
  axios
    .get(`/api/v1/cities/${id}/`)
    .then(response => {
      dispatch({
        type: GET_CITY,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getCitiesByUf = idUf => dispatch =>{
  return axios
  .get(`/api/v1/cities/?uf=${idUf}`)
  .then(response => {
    dispatch({
      type: GET_CITIES,
      payload: response.data
    });
  })
  .catch(error =>{
    toastOnError(error);
  });
}