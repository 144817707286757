import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IMaskInput } from "react-imask";
import { withRouter, Link } from "react-router-dom";
import { cnpjMask, cnpjValidation} from "../../utils/mask"
import { addUserPerson, updateUserPerson, getUserPerson } from "./UserPersonsActions";
import {getCompanies} from "components/company/CompanyActions"
import Loader from "components/utils/Loader"
import { Authorized } from "utils/AuthUtil";

// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    InputGroup,
    Row,
    Col,
    Modal,
    Table,
    Accordion,
    Checkbox
} from "react-bootstrap";

// https://www.codingame.com/playgrounds/9396/redux-form-validation-tutorial

class AddUserPerson extends Component {
    constructor(props, context) {
        super(props, context);
        const user = JSON.parse(localStorage.getItem("user"))
        
        this.state = {
            pe: {
                first_name: "",
                last_name: "",
                user: {
                    username: "",
                    password: "",
                    email: "",
                    emailToCheck:"",
                    passwordToCheck: ""
                },
                company: "",
                enabled:true
            },
            validated: false,
            loading: false,
            can_view_companies: user['all_permissions'].includes("operations.view_company")
        }

        if (this.state.can_view_companies)
            this.props.getCompanies()

        this.props.userPersonsReduc.userUserPerson = this.state.pe
    }
    

    setValidated = (v) => {
        this.setState({ validated: v });
    }

    handleSubmit = (event) => {
        const form = document.getElementById("addUserPerson")
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setValidated(true);
        if (form.checkValidity() !== false) {

            this.onSaveClick();            
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.userPersonsReduc.userUserPerson.id !== prevState.pe.id) {
            const { userUserPerson } = nextProps.userPersonsReduc;
            return {
                pe: userUserPerson,
                validated: prevState.validated
            };
        }
        else
            return null
    }
    componentDidMount() {
        const { id } = this.props.match.params;
        if (id != null) {
            this.props.getUserPerson(id);
        }
        else {
            this.props.userPersonsReduc.userUserPerson = this.state.pe
        }
    }

    onChangeCheckbox = e => {        
        var pe = { ...this.state.pe }        
        pe[e.target.name] = e.target.value === 'true' ? false : true
        this.setState({ pe });
    }

    onChange = e => {        
        var pe = { ...this.state.pe }
        pe[e.target.name] = e.target.value
        this.setState({ pe });
    };

    onChangeUser = e => {
        const {user} = {...this.state.pe}
        user[e.target.name] = e.target.value
        this.setState({user})
    }

    onSaveClick = (event) => {
        let userUserPerson = this.state.pe;
        // userUserPerson.user.username = userUserPerson.user.email.split('@')[0]
        if (!this.state.pe.id){
            userUserPerson.user.username = userUserPerson.user.email
            this.setState({loading:true})
            userUserPerson.user.password = Math.random().toString(36).slice(-8);
            userUserPerson.user.passwordToCheck = userUserPerson.user.password;
            this.props.addUserPerson(userUserPerson).then(() => {
                this.setState({loading:false})
                this.props.history.push('/manage/userPersons');
                }
            )
            .catch(error => {
                this.setState({loading:false})
              }
            )
        }
        else{
            this.setState({loading:true})            

            userUserPerson = {... this.state.pe}
            userUserPerson.user = {id: userUserPerson.user.id}

            this.props.updateUserPerson(this.state.pe.id, userUserPerson).then(() => {
                this.setState({loading:false})
                this.props.history.push('/manage/userPersons');
                }
            ).catch(error => {
                this.setState({loading:false})
              }
            )
        }
    };

    render() {
        const { companies } = this.props.companiesReduc
        let companies_options = companies.map(c => {
            return <option key={c.id} value={c.id}>{c.corporate_name}</option>
        })
        return (
            <>
            <Loader loading={this.state.loading} />
            <Container fluid>
                <Row>                
                    <Col md="12">
                        {/* <Card  className="bg-dark text-white"> */}
                        <Card>
                            <Card.Header>
                                <Card.Title as="h4" className="text-center text-secondary font-weight-bold ">
                                    {this.state.can_view_companies && "Cadastro de Administrador da Empresa Gestora"}
                                    {!this.state.can_view_companies && "Cadastro de Operadores"}
                                    
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form id="addUserPerson" noValidate validated={this.state.validated}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="first_name">
                                            <Form.Label className="font-weight-bold">Nome do Responsável</Form.Label>
                                            <Form.Control required type="text" name="first_name" placeholder="nome" value={this.state.pe.first_name}
                                                onChange={this.onChange} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="last_name">
                                            <Form.Label className="font-weight-bold">Sobrenome do Responsável</Form.Label>
                                            <Form.Control required type="text" name="last_name" placeholder="sobrenome" value={this.state.pe.last_name}
                                                onChange={this.onChange} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} xs={6} controlId="usernameId">
                                            <Form.Label className="font-weight-bold">E-mail do responsável</Form.Label>
                                            <Form.Control
                                            required
                                            // isInvalid={this.props.createUser.usernameError}
                                            type="text"
                                            disabled={this.state.pe.id}
                                            name="email"
                                            placeholder="Seu E-mail"
                                            value={this.state.pe.user.email}
                                            onChange={this.onChangeUser}
                                            />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                            {/* {this.props.createUser.usernameError} */}
                                            Campo obrigatório
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        {!this.state.pe.id && <Form.Group as={Col} xs={6} controlId="usernameIdRepeated">
                                            <Form.Label className="font-weight-bold">Confirme o E-mail do responsável</Form.Label>
                                            <Form.Control
                                            required
                                            // isInvalid={this.props.createUser.usernameError}
                                            type="text"
                                            name="emailToCheck" 
                                            placeholder="Seu E-mail"
                                            // value={this.state.pe.user.emailToCheck}
                                            onChange={this.onChangeUser}
                                            />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                            {/* {this.props.createUser.usernameError} */}
                                            Campo obrigatório
                                            </Form.Control.Feedback>
                                        </Form.Group>}
                                    </Row>

                                    {this.state.can_view_companies && "Cadastro de Usuários"
                                    &&
                                    <Row className="mb-3">
                                        <Authorized>
                                            <Form.Group as={Col} md="4">                                            
                                                <Form.Label className="font-weight-bold" >Empresa Gestora</Form.Label>
                                                <Form.Control
                                                    as="select" required aria-label="Gestoras" name="company" onChange={this.onChange} value={this.state.pe.company}>
                                                    <option value="">--- SELECIONE ---</option>
                                                    {companies_options}
                                                </Form.Control>
                                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            </Form.Group>
                                        </Authorized>
                                    </Row>}
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4"> 
                                            <Form.Label className="font-weight-bold" >Ativo</Form.Label>
                                            <Form.Check id="enabled" name="enabled"
                                              className="mb-1 pl-0">
                                                <Form.Check.Label>
                                                <Form.Check.Input id="enabled" name="enabled" onChange={this.onChangeCheckbox}
                                                    checked = {this.state.pe.enabled}
                                                    value = {this.state.pe.enabled}
                                                    type="checkbox"
                                                ></Form.Check.Input>
                                                <span className="form-check-sign"></span>
                                                </Form.Check.Label>
                                            </Form.Check>
                                        </Form.Group>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Button className="btn btn btn-primary active btn-floating position-sticky" style={{ bottom: '20px', right: '20px', }} type="button" onClick={this.handleSubmit} >
                            Salvar
                        </Button>{' '}
                        <Link to="/manage/userPersons/" className="btn btn btn-danger active btn-floating position-sticky" style={{ bottom: '20px', right: '20px', }}>
                            Cancelar
                        </Link>
                    </Col>
                </Row>
            </Container>
            </>
        )
    }
}

AddUserPerson.propTypes = {
    addUserPerson: PropTypes.func.isRequired,
    updateUserPerson: PropTypes.func.isRequired,
    getUserPerson: PropTypes.func.isRequired,
    userPersonsReduc: PropTypes.object.isRequired,
    companiesReduc: PropTypes.object.isRequired,
    getCompanies: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    userPersonsReduc: state.userPersonsReduc,
    companiesReduc: state.companiesReduc
});

export default connect(mapStateToProps, { addUserPerson, updateUserPerson, getUserPerson, getCompanies })(withRouter(AddUserPerson));