import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import AddProduct from "../components/product/AddProduct";
import ProductList from "../components/product/ProductList"

class Product extends Component {
    constructor() {
        super();
        this.state = {
            listing: true,
        };
    }

    render() {
        const { listing } = this.state;
        return (
            <div>
                <Container>
                    <ProductList />
               
                        <Link  className="btn btn-primary position-sticky fixed-button" to="/manage/products/edit">
                            Adicionar Produto
                        </Link>
                 
                </Container>
            </div>
        );
    }
}

class CreateProduct extends Component {
    constructor() {
        super();
    }

    render() {

        return (
            <div>
                <Container>
                    <AddProduct />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const ListPr = connect(mapStateToProps, {
})(withRouter(Product))

const CreatePr = connect(mapStateToProps, {
})(withRouter(CreateProduct))

export { ListPr, CreatePr }