import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

export const exportCSV = (selectionOnly, dt) => {
    dt.current.exportCSV({ selectionOnly });
};

export const exportPdf = (data, title, exportColumns) => {
    import('jspdf').then((jsPDF) => {
        import('jspdf-autotable').then(() => {
            const doc = new jsPDF.default(0, 0);
            doc.autoTable(exportColumns, data);
            doc.save(title);
        });
    });
};

export const exportExcel = (data, title) => {
    import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        saveAsExcelFile(excelBuffer, title);
    });
};

export const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
        if (module && module.default) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });

            module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        }
    });
};
export const header = (data, title, dt, exportColumns) => (
    <div className="flex align-items-center justify-content-end gap-2">
        <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    {/* <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" /> */}
                </span>

        <Button title="CSV" type="button" icon="pi pi-file" rounded={true} onClick={() => exportCSV(false, dt)} data-pr-tooltip="CSV" />
        <Button title="XLS" type="button" icon="pi pi-file-excel" severity="success" rounded={true} onClick={() => exportExcel(data, title)} data-pr-tooltip="XLS" />
        <Button title="PDF" type="button" icon="pi pi-file-pdf" severity="warning" rounded={true} onClick={() => exportPdf(data, title, exportColumns)} data-pr-tooltip="PDF" />
    </div>
);