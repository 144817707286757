import { GET_DESTINATIONS, GET_DESTINATION, ADD_DESTINATION, DELETE_DESTINATION, UPDATE_DESTINATION} from "./DestinationTypes";

const initialState = {
  destinations: [],
  destination:{}
};

export const destinationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DESTINATIONS:
      return {
        ...state,
        destinations: action.payload
      };
    case GET_DESTINATION:
      return {
        ...state,
        destination: action.payload
      };
    case ADD_DESTINATION:
      return {
        ...state,
        destinations: [...state.destinations, action.payload]
      };
    case DELETE_DESTINATION:
      return {
        ...state,
        destinations: state.destinations.filter((item, index) => item.id !== action.payload)
      };
    case UPDATE_DESTINATION:
      const updatedDestinations = state.destinations.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        destinations: updatedDestinations
      };
    default:
      return state;
  }
};