import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { deleteUserPerson, updateUserPerson } from "./UserPersonsActions";
import { PencilFill, TrashFill, Check2Circle } from 'react-bootstrap-icons';
import {
  Button,
  Modal,

} from "react-bootstrap";

class UserPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
    }
  }

  onDeleteClick = () => {
    const { userUserPerson } = this.props;
    this.props.deleteUserPerson(userUserPerson.id);
  };
  onEditClick = () => {

  }
  onRemoveClick = () => {
    this.setState({
      isDeleting: true
    })
  }
  onCancelClick = () => {
    this.setState({
      isDeleting: false,
    })
  }

  onUpperCaseClick = () => {
    const { userUserPerson } = this.props;
    this.props.updateUserPerson(userUserPerson.id, {
      content: userUserPerson.content.toUpperCase()
    });
  };
  onLowerCaseClick = () => {
    const { userUserPerson } = this.props;
    this.props.updateUserPerson(userUserPerson.id, {
      content: userUserPerson.content.toLowerCase()
    });
  };

  render() {
    const { userUserPerson } = this.props;
    return (
      <div className="btn-group">
        <Link to={`/manage/userPersons/edit/${userUserPerson.id}`} role="button" className="btn btn-primary">
          <PencilFill color="royalblue" />
        </Link>
        {userUserPerson.enabled == 1 &&
        <Button variant="danger" className="btn btn-primary" title="Excluir Usuário" onClick={this.onRemoveClick}>
          <TrashFill color="red" />
        </Button>}
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={this.state.isDeleting} >
          <Modal.Body>
            <h6>Deseja desabilitar o usuário {userUserPerson.first_name}?</h6>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-danger active" onClick={() => this.onCancelClick()}>Não</Button>
            <Button onClick={() => this.onDeleteClick()}>Sim</Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

UserPerson.propTypes = {
  userUserPerson: PropTypes.object.isRequired
};
const mapStateToProps = state => ({});

export default connect(mapStateToProps, { deleteUserPerson, updateUserPerson })(
  withRouter(UserPerson)
);