import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_COMMERCIAL_PRODUCTS, GET_COMMERCIAL_PRODUCT, ADD_COMMERCIAL_PRODUCT, DELETE_COMMERCIAL_PRODUCT, UPDATE_COMMERCIAL_PRODUCT, MODAL_ADD_COMMERCIAL_PRODUCT} from "./CommercialProductTypes";
import { toast } from "react-toastify";

export const getCommercialProducts = (options = {company:null}) => dispatch => {

  var url = "/api/v1/commercialproducts/"
  var complement = []
  if (options.company){
    complement.push(`company=${options.company}`)
  }

  if (options.enabled){
    complement.push(`enabled=${options.enabled}`)
  }

  if (complement.length > 0){
    url += "?" + complement.join("&")
  }

  return axios
    .get(url)
    .then(response => {      
      dispatch({
        type: GET_COMMERCIAL_PRODUCTS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const addCommercialProduct = (product, updating = false) => dispatch => {
  return axios
    .post("/api/v1/commercialproducts/", product)
    .then(response => {
      dispatch({
        type: ADD_COMMERCIAL_PRODUCT,
        payload: response.data
      });
      if (updating){
        toastOnSave("Atualização realizada com sucesso")
      }
      else{
        toastOnSave("Inclusão realizada com sucesso")
      }
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const deleteCommercialProduct = id => dispatch => {
  axios
    .delete(`/api/v1/commercialproducts/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_COMMERCIAL_PRODUCT,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getCommercialProduct = id => dispatch => {
  return axios
    .get(`/api/v1/commercialproducts/${id}/`)
    .then(response => {
      dispatch({
        type: GET_COMMERCIAL_PRODUCT,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const updateCommercialProduct = (id, company) => dispatch => {
  return axios
    .patch(`/api/v1/commercialproducts/${id}/`, company)
    .then(response => {
      dispatch({
        type: UPDATE_COMMERCIAL_PRODUCT,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const modalAddCommercialProduct = (commercialproduct) => dispatch => {
  dispatch({
    type: MODAL_ADD_COMMERCIAL_PRODUCT,
    payload: commercialproduct
  })
}