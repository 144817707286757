import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_SUPPLIERS, GET_SUPPLIER, ADD_SUPPLIER, DELETE_SUPPLIER, UPDATE_SUPPLIER} from "./SupplierTypes";
import { toast } from "react-toastify";

export const getSuppliers = (options={cnpj:null}) => dispatch => {
  var url = "/api/v1/suppliers/"
  var complement = []
  if (options.cnpj){
    complement.push(`cnpj=${options.cnpj}`)
  }

  if (complement.length > 0){
    url += "?" + complement.join("&")
  }

  return axios
    .get(url)
    .then(response => {      
      dispatch({
        type: GET_SUPPLIERS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const addSupplier = supplier => dispatch => {
  return axios
    .post("/api/v1/suppliers/", supplier)
    .then(response => {
      dispatch({
        type: ADD_SUPPLIER,
        payload: response.data
      });
      toastOnSave("Inclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const deleteSupplier = id => dispatch => {
  axios
    .delete(`/api/v1/suppliers/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_SUPPLIER,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getSupplier = id => dispatch => {
  axios
    .get(`/api/v1/suppliers/${id}/`)
    .then(response => {
      dispatch({
        type: GET_SUPPLIER,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const updateSupplier = (id, supplier) => dispatch => {
  return axios
    .patch(`/api/v1/suppliers/${id}/`, supplier)
    .then(response => {
      dispatch({
        type: UPDATE_SUPPLIER,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};