import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { deleteCommercialProduct, updateCommercialProduct } from "./CommercialProductActions";
import { Button } from "react-bootstrap";
import { PencilFill, TrashFill, Check2Circle} from 'react-bootstrap-icons';
import ConfirmationModal from "components/utils/ConfirmationModal";

class CommercialProduct extends Component {

  onConfirmDeleteClick = () => {
    this.setState({show: true});
  }

  onHide = () => {
    this.setState({visible: false});
  }

  onDeleteClick = () => {
    const { commercialproduct } = this.props;
    this.props.deleteCommercialProduct(commercialproduct.id);
  };

  onEditClick = (commercialproduct) => {
    if (!this.props.isModalAddCommercialProduct)
      this.props.history.push(`/manage/commercialproducts/edit/${commercialproduct.id}`);
    // else
      // this.props.modalAddCommercialProduct(commercialproduct)
  }

  render() {
    const { commercialproduct } = this.props;
    return (      
      <div className="btn-group">        
        <Button className="btn btn-primary" onClick={() => this.onEditClick(commercialproduct)}>
          <PencilFill color="royalblue" />
        </Button>
        <ConfirmationModal title="Deseja inativar este produdo composto" function={this.onDeleteClick} />
      </div>
    );
  }
}

CommercialProduct.propTypes = {
  commercialproduct: PropTypes.object.isRequired
  // modalAddCommercialProduct: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  isModalAddCommercialProduct: state.commercialproductsReduc.isModalAddCommercialProduct
});

export default connect(mapStateToProps, { deleteCommercialProduct, updateCommercialProduct
  // modalAddCommercialProduct 
})(
  withRouter(CommercialProduct)
);