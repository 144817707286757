import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { deleteSupplier, updateSupplier } from "./SupplierActions";
import { PencilFill, TrashFill, Check2Circle} from 'react-bootstrap-icons';
import {
  Button,
  Modal,
} from "react-bootstrap";

class Supplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
    }
  }

  onDeleteClick = () => {
    const { supplier } = this.props;
    this.props.deleteSupplier(supplier.id);
  };
  onEditClick = () => {
    
  }
  onRemoveClick = () => {
    this.setState({
      isDeleting: true
    })
  }
  onCancelClick = () => {
    this.setState({
      isDeleting: false,
    })
  }

  onUpperCaseClick = () => {
    const { supplier } = this.props;
    this.props.updateSupplier(supplier.id, {
      content: supplier.content.toUpperCase()
    });
  };
  onLowerCaseClick = () => {
    const { supplier } = this.props;
    this.props.updateSupplier(supplier.id, {
      content: supplier.content.toLowerCase()
    });
  };

  render() {
    const { supplier } = this.props;
    return (      
      <div className="btn-group">        
        <Link to={`/manage/suppliers/edit/${supplier.id}`} role="button" className="btn btn-primary">          
          <PencilFill color="royalblue" />
        </Link>
        <Button variant="danger" className="btn btn-primary" onClick={this.onRemoveClick}>
          <TrashFill color="red" />
        </Button>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={this.state.isDeleting} >
          <Modal.Body>
            <h6>Deseja desabilitar o fornecedor "{supplier.corporate_name}"?</h6>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-danger active" onClick={() => this.onCancelClick()}>Não</Button>
            <Button onClick={() => this.onDeleteClick()}>Sim</Button>
          </Modal.Footer>
        </Modal>
      </div>      
    );
  }
}

Supplier.propTypes = {
    supplier: PropTypes.object.isRequired
};
const mapStateToProps = state => ({});

export default connect(mapStateToProps, { deleteSupplier, updateSupplier })(
  withRouter(Supplier)
);