import { setMask, cnpjMask, cnpjValidation, onlyNumber, convertDecimalPlaces, quantidadeMask} from "./mask"

const parseDecimalNumber = (strDensity) => {
    var density = 0
    if (strDensity) {
        density = parseFloat(strDensity.replace(',', '.'))
    }
    return density
}

export const onChangeKilos = (e, density, form) => {
    density = parseDecimalNumber(density)
    var liters = ""
    if (e.target.value[0] === ","){
        form.setValidated(false);
    }
    if (e.target.value !== "" && density){
        liters = (parseDecimalNumber(e.target.value) / density).toFixed(3)
        liters = liters.toString()
    }
    return liters
}

export const onChangeLiters = (e, density, form) => {
    density = parseDecimalNumber(density)
    var kilos = ""
    // e.target.value = quantidadeMask(e.target.value)
    if (e.target.value[0] === ","){
        form.setValidated(false);
    }

    if (e.target.value !== "" && density){
        kilos = (parseDecimalNumber(e.target.value) * density).toFixed(3)
        kilos = kilos.toString()
    }
    return kilos
}