import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { deleteCompany, updateCompany } from "./CompanyActions";
import { PencilFill, TrashFill, Check2Circle} from 'react-bootstrap-icons';
import { Authorized } from "utils/AuthUtil";
import ConfirmationModal from "components/utils/ConfirmationModal";

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false
    }
  }

  onDeleteClick = () => {
    const { company } = this.props;
    this.props.deleteCompany(company.id);
  };
  render() {
    const { company } = this.props;
    return (      
      <div className="btn-group">        
        <Link to={`/manage/companies/edit/${company.id}`} role="button" className="btn btn-primary">          
          <PencilFill color="royalblue" />
        </Link>
        <Authorized>
          <ConfirmationModal title="Deseja inativar este chamado" function={this.onDeleteClick} />
        </Authorized>
      </div>      
    );
  }
}

Company.propTypes = {
    company: PropTypes.object.isRequired
};
const mapStateToProps = state => ({});

export default connect(mapStateToProps, { deleteCompany, updateCompany })(
  withRouter(Company)
);