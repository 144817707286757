import axios from "axios";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import { SET_TOKEN, SET_CURRENT_USER, UNSET_CURRENT_USER } from "./LoginTypes";
import { setAxiosAuthToken, toastOnError, toastOnSave } from "../../utils/Utils";

export const recoveryPassword = (email, redirectTo) => dispatch => {
  return axios

    .post("/api/v1/users/reset_password/", {"email":email},{
      headers: {
        'Authorization': `` 
      }
    })
    .then(response => {
      if (response.data == 1){
        toastOnSave("Um e-mail de recuperação de senha foi enviado para o seu e-mail")
        if (redirectTo !== "") {
          dispatch(push(redirectTo));
        }
        
      }
      else{
        toastOnError("Usuário não encontrado ou inativo. Verifique o e-mail digitado e tente novamente.")
      }
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
}

export const resetPassword = (userData, redirectTo) => dispatch => {
  return axios
    .post("/api/v1/users/set_password/", userData)
    .then(response => {
      toastOnSave("Atualização realizada com sucesso")
      dispatch(logout())
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
}

export const resetForgotPassword = (userData) => dispatch => {
  return axios
    .post("/api/v1/users/reset_password_confirm/", userData, {
      headers: {
        'Authorization': `` 
      }
    })
    .then(response => {
      toastOnSave("Atualização realizada com sucesso. Faça o login")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
}

export const login = (userData, redirectTo) => dispatch => {
  return axios
    .post("/api/v1/token/login/", userData,{
      headers: {
        'Authorization': `` 
      }
    })
    .then(response => {
      const { auth_token } = response.data;
      setAxiosAuthToken(auth_token);
      dispatch(setToken(auth_token));
      dispatch(getCurrentUser(redirectTo));
    })
    .catch(error => {
      dispatch(unsetCurrentUser());
      toastOnError(error);
      throw error
    });
};

export const getCurrentUser = redirectTo => dispatch => {
  axios
    .get("/api/v1/users/me/")
    .then(response => {
      // const user = {
      //   username: response.data.username,
      //   email: response.data.email
      // };
      const user = response.data
      dispatch(setCurrentUser(user, redirectTo));
    })
    .catch(error => {
      dispatch(unsetCurrentUser());
      toastOnError(error);
    });
};

export const setCurrentUser = (user, redirectTo) => dispatch => {
  localStorage.setItem("user", JSON.stringify(user));
  dispatch({
    type: SET_CURRENT_USER,
    payload: user
  });
  if (redirectTo !== "") {
    dispatch(push(redirectTo));
  }
};

export const setToken = token => dispatch => {
  setAxiosAuthToken(token);
  localStorage.setItem("token", token);
  dispatch({
    type: SET_TOKEN,
    payload: token
  });
};

export const unsetCurrentUser = () => dispatch => {
  setAxiosAuthToken("");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  dispatch({
    type: UNSET_CURRENT_USER
  });
};

export const logout = () => dispatch => {
  axios
    .post("/api/v1/token/logout/")
    .then(response => {
      dispatch(unsetCurrentUser());
      dispatch(push("/"));
      toast.success("Logout successful.");
    })
    .catch(error => {
      dispatch(unsetCurrentUser());
      toastOnError(error);
    });
};