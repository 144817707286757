import React from 'react';
import { IMaskInput } from "react-imask";
import {
    Form
} from "react-bootstrap";
import { toastOnError } from "../../utils/Utils";

class ControlConcentration extends React.Component {
    constructor(props) {
        super(props);
    }

    onChange = (e) => {
        if (e.target.value === "0"){
            toastOnError("A concentração deve ser maior que zero")
            e.target.value = ""
        }
        this.props.onChangeConcentration(e);
    }

    render() {
        return (
            <>
            <Form.Label className="font-weight-bold">Conc.(%)</Form.Label>
            <Form.Control required type='number' name="concentration" value={this.props.value}
                placeholder="000"
                as={IMaskInput} mask={Number}
                min={this.props.min} max={this.props.max}
                onChange={this.onChange} />
            <Form.Control.Feedback >Ok</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
                Campo obrigatório.
            </Form.Control.Feedback>
            </>
        );
    }
}

export default ControlConcentration;