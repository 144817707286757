import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import AddCompany from "../components/company/AddCompany";
import CompanyList from "../components/company/CompanyList"
import { Authorized } from "utils/AuthUtil";

class Company extends Component {
    constructor() {
        super();
        this.state = {
            listing: true,
        };
    }

    render() {
        const { listing } = this.state;
        return (
            <div>
                <Container>
                    <CompanyList />
                    <Authorized permissions={["operations.add_company"]}>
                        <Link className="btn btn-primary position-sticky fixed-button" to="/manage/companies/edit">
                            Adicionar Empresa Gestora
                        </Link>
                    </Authorized>
                </Container>
            </div>
        );
    }
}

class CreateCompany extends Component {
    constructor() {
        super();
    }

    render() {

        return (
            <div>
                <Container>
                    <AddCompany />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const ListCo = connect(mapStateToProps, {
})(withRouter(Company))

const CreateCo = connect(mapStateToProps, {
})(withRouter(CreateCompany))

export { ListCo, CreateCo }