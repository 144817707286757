import { GET_STOCKS, GET_STOCK, ADD_STOCK, DELETE_STOCK, UPDATE_STOCK} from "./StockTypes";

const initialState = {
  stocks: [],
  stock:{}
};

export const stocksReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STOCKS:
      return {
        ...state,
        stocks: action.payload
      };
    case GET_STOCK:
      return {
        ...state,
        stock: action.payload
      };
    case ADD_STOCK:
      return {
        ...state,
        stocks: [...state.stocks, action.payload]
      };
    case DELETE_STOCK:
      return {
        ...state,
        stocks: state.stocks.filter((item, index) => item.id !== action.payload)
      };
    case UPDATE_STOCK:
      const updatedStocks = state.stocks.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        stocks: updatedStocks
      };
    default:
      return state;
  }
};