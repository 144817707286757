import { GET_SUPPLIERS, GET_SUPPLIER, ADD_SUPPLIER, DELETE_SUPPLIER, UPDATE_SUPPLIER} from "./SupplierTypes";

const initialState = {
  suppliers: [],
  supplier:{}
};

export const suppliersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload
      };
    case GET_SUPPLIER:
      return {
        ...state,
        supplier: action.payload
      };
    case ADD_SUPPLIER:
      return {
        ...state,
        suppliers: [...state.suppliers, action.payload]
      };
    case DELETE_SUPPLIER:
      return {
        ...state,
        suppliers: state.suppliers.filter((item, index) => item.id !== action.payload)
      };
    case UPDATE_SUPPLIER:
      const updatedSuppliers = state.suppliers.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        suppliers: updatedSuppliers
      };
    default:
      return state;
  }
};