import { GET_PRODUCTS, GET_PRODUCT, ADD_PRODUCT, DELETE_PRODUCT, UPDATE_PRODUCT, MODAL_ADD_PRODUCT, ADD_PRODUCT_SUPPLIER, GET_PRODUCT_SUPPLIERS, DELETE_PRODUCT_SUPPLIER, UPDATE_PRODUCT_SUPPLIER} from "./ProductTypes";

const initialState = {
  products: [],
  product:{},
  product_supplier: {},
  product_suppliers: [],
  isModalAddProduct:false
};

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {    
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };
    case GET_PRODUCT:
      return {
        ...state,
        product: action.payload
      };
      case GET_PRODUCT_SUPPLIERS:
        return {
          ...state,
          product_suppliers: action.payload
        };
    case ADD_PRODUCT:
      return {
        ...state,
        products: [...state.products, action.payload]
      };
      case ADD_PRODUCT_SUPPLIER:
        return {
          ...state,
          product_suppliers: [...state.product_suppliers, action.payload]
        };
      case UPDATE_PRODUCT_SUPPLIER:
        const updatedProductsupplier = state.product_suppliers.map(item => {
          if (item.id === action.payload.id) {
            return { ...item, ...action.payload };
          }
          return item;
        });
        return {
          ...state,
          product_suppliers: updatedProductsupplier,
          product_supplier:action.payload
        };
    case DELETE_PRODUCT:
      return {
        ...state,
        products: state.products.filter((item, index) => item.id !== action.payload)
      };
      case DELETE_PRODUCT_SUPPLIER:
        return {
          ...state,
          product_suppliers: state.product_suppliers.filter((item, index) => item.id !== action.payload)
        };
    case UPDATE_PRODUCT:
      const updatedProducts = state.products.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        products: updatedProducts
      };
    case MODAL_ADD_PRODUCT:
      return {
        ...state,
        isModalAddProduct: !state.isModalAddProduct,
        product: action.product,
        product_supplier: action.product_supplier
      }
    default:
      return state;
  }
};