import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_COMPANIES, GET_COMPANY, ADD_COMPANY, DELETE_COMPANY, UPDATE_COMPANY} from "./CompanyTypes";
import { toast } from "react-toastify";

export const getCompanies = () => dispatch => {
  return axios
    .get("/api/v1/companies/")
    .then(response => {      
      dispatch({
        type: GET_COMPANIES,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const addCompany = company => dispatch => {
  return axios
    .post("/api/v1/companies/", company)
    .then(response => {
      dispatch({
        type: ADD_COMPANY,
        payload: response.data
      });
      toastOnSave("Inclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const deleteCompany = id => dispatch => {
  axios
    .delete(`/api/v1/companies/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_COMPANY,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getCompany = id => dispatch => {
  return axios
    .get(`/api/v1/companies/${id}/`)
    .then(response => {
      dispatch({
        type: GET_COMPANY,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const updateCompany = (id, company) => dispatch => {
  return axios
    .patch(`/api/v1/companies/${id}/`, company)
    .then(response => {
      dispatch({
        type: UPDATE_COMPANY,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const getEnabledCompany = (enabled) => dispatch => {
  return axios
  .get(`/api/v1/companies/?enabled=${enabled}`)
  .then(response => {      
    dispatch({
      type: GET_COMPANIES,
      payload: response.data
    });
  })
  .catch(error => {
    toastOnError(error);
  });
}
